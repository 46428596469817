import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';


// App's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyD0-qJlgu6qA2v9dvGN23WaCZ5wfoO2Zis',
  authDomain: 'dashboard.heyhio.com',
  projectId: 'mongoosebeta',
  storageBucket: 'mongoosebeta.appspot.com',
  messagingSenderId: '247644047819',
  appId: '1:247644047819:web:52e0d2085882521a168e4e'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export default app
