import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Header from '../../components/Header';
import Chat from './chat';
import Update from './update';
import HistoryTable from './history';
import { useState, useEffect } from 'react';
import { paths } from '../../lib/routes';
import { Link, useLocation } from 'react-router-dom';
import '../../statics/styles/pages/conversations.scss';

const ConversationsPage = () => {
  const [showHistory, setShowHistory] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryQuestion = params.get('question');
    const queryAnswer = params.get('answer');
    if (queryQuestion && queryAnswer) {
      setQuestion(queryQuestion);
      setAnswer(queryAnswer);
      setAnimate(true);
    }
  }, [location]);

  return (
    <div className="main-container">
      <Header title="Test and Adjust the Model"/>
      <Chat
        setAnimate={setAnimate}
        setQuestion={setQuestion}
        setAnswer={setAnswer}
      />
      <Update
        animate={animate}
        question={question}
        answer={answer}
        setQuestion={setQuestion}
        setAnswer={setAnswer}
      />
      <div className="conversation-footer" style={!showHistory ? { marginBottom: '20px' } : {}}>
        <div className="disclaimer" style={{ textAlign: 'center' }}>Want to add a information in a different format to your knowledge base? Try the <Link to={paths.DOCUMENTS}>Reference Document </Link> Upload page.</div>
        <Button onClick={() => setShowHistory(!showHistory)} className="display-history" variant={showHistory ? "outlined" : "contained"} endIcon={ showHistory ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} size="small">
          View Model Update History
        </Button>
      </div>
      { showHistory && <HistoryTable /> }
    </div>
  );
};

export default ConversationsPage;
