import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  collection, where, query, getDocs, doc } from 'firebase/firestore';
import { db } from '../app/firebase';

export const fetchEscalationData = createAsyncThunk('service/fetchEscalationData', async ({ startDate, endDate }, { getState }) => {
  const state = getState();
  const building = state.building.currentBuilding;
  const organization = state.building.organization;

  if (!building) {
    return [];
  }

  const escalationRef = collection(
    db,
    'organization',
    organization.id,
    'building',
    building.id,
    'escalation'
  );

  const escalationSnapshot = await getDocs(escalationRef);

  return escalationSnapshot.docs.map(e => e.data());
});

const escalationSlice = createSlice({
  name: 'escalation',
  initialState: { escalations: [], loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEscalationData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEscalationData.fulfilled, (state, action) => {
        state.loading = false;
        state.escalations = action.payload;
        state.error = null;
      })
      .addCase(fetchEscalationData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default escalationSlice.reducer;
