import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import moment from 'moment/moment';
import TableContainer from '@mui/material/TableContainer';
import * as React from 'react';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import { useEffect } from 'react';
import { fetchBlastTexts, getBlastTexts } from '../../../slices/usage';
import { useDispatch, useSelector } from 'react-redux';
import { getUserBuilding } from '../../../slices/building';

const Section = styled(Paper)(({ theme }) => ({
  borderRadius: '12px',
  backgroundColor: 'white',
  padding: 0,
  textAlign: 'center',
  color: '#31394d',
  width: '900px'
}));

const StyledTableContainer = styled(TableContainer)({
  borderRadius: '12px',
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: 'pre-line',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.table.main,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.table.alternate,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.table.main,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const HistoryBlast = () => {
  const dispatch = useDispatch();
  const building = useSelector(getUserBuilding);
  const blast = useSelector(getBlastTexts);

  useEffect(() => {
    dispatch(fetchBlastTexts());
  }, [dispatch, building]);

  return blast?.length > 0 ? (
    <Section elevation={2}>
      <StyledTableContainer>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            bgcolor: (theme) => theme.palette.table.main
          }}
        >
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Blast Text History
          </Typography>
        </Toolbar>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Message</StyledTableCell>
              <StyledTableCell align="left">Recipients</StyledTableCell>
              <StyledTableCell align="left">Added By</StyledTableCell>
              <StyledTableCell align="left">Date Added</StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blast.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">{row.message}</StyledTableCell>
                <StyledTableCell align="left">{row.isAll ? 'All' : row.recipients.map(r => r.name).join(', ')}</StyledTableCell>
                <StyledTableCell align="left">{row.author}</StyledTableCell>
                <StyledTableCell align="left">{moment(row.timestamp.toDate()).format('MMMM Do YYYY')}</StyledTableCell>
                <StyledTableCell align="left">{row.status}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Section>
  ) : null;
};

export default HistoryBlast;
