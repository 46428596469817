import React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const StyledTextField = styled(TextField)(({ height }) => ({
  'flex': 1,
  '& .MuiInputBase-root': {
    borderRadius: '28px',
    backgroundColor: 'white',
    height: height || 'auto',
  }
}));

const SearchBar = ({ onSearch, height, searchOnKeydown }) => {
  const [query, setQuery] = React.useState('');

  const handleSearch = (s) => {
    if (onSearch) {
      onSearch(s || query);
    }
  };

  const handleKeyDown = (event) => {
    const isEnterKey = event.key === 'Enter' || event.keyCode === 13;
    if (isEnterKey) {
      handleSearch();
    }
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
    if (searchOnKeydown) {
      handleSearch(e.target.value);
    }
  };

  return (
    <StyledTextField
      variant="outlined"
      fullWidth
      placeholder="Search..."
      value={query}
      height={height}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleSearch}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;