import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../app/firebase';
import {
  addDoc,
  collection,
  query,
  orderBy,
  getDocs,
  doc,
  serverTimestamp
} from 'firebase/firestore';

export const fetchModelUpdateHistory = createAsyncThunk('model/fetchModelUpdateHistory', async (_, { getState }) => {
  const history = [];
  const { building: { organization, currentBuilding: { id: buildingId } } } = getState();
  const modelHistoryQuery = query(
    collection(db,'organization', organization.id, 'building', buildingId, 'updateHistory'),
    orderBy('timestamp', 'desc')
  );
  const modelHistoryDocs =  await getDocs(modelHistoryQuery);

  modelHistoryDocs.forEach(d => history.push({ id: d.id, ...d.data() }));

  return history;
});

export const setModelUpdateHistory = createAsyncThunk(
  'model/setModelUpdateHistory',
  async (payload, { getState }) => {
    const { building: { organization, currentBuilding: { id: buildingId, name: buildingName } }, auth: { user } } = getState();
    const author = user?.displayName ?? 'Unknown User';
    const connectionRef = doc(db, 'organization', organization.id, 'building', buildingId);
    const updateHistoryRef = collection(connectionRef, 'updateHistory');
    const text = 'The user would like to update their model with the following parameters:\n\n' +
      'Question: ' + payload.question + '\n' +
      'Answer: ' + payload.answer + '\n' +
      'Statement: ' + payload.statement + '\n' +
      'Model: ' + buildingName + '\n' +
      'Author: ' + author + '\n';

    // Assuming 'manager' is already defined
    const data = {
      ...payload,
      to: ['hamza@heyhio.com', 'jack@heyhio.com'],
      message: {
        subject: 'Update Model Request',
        text,
      },
      author,
      timestamp: serverTimestamp(),
      status: 'Pending',
    };

    await addDoc(updateHistoryRef, data);
    return payload;
});

const modelSlice = createSlice({
  name: 'model',
  initialState: { updateHistory: [], loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchModelUpdateHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchModelUpdateHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.updateHistory = action.payload;
      })
      .addCase(fetchModelUpdateHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(setModelUpdateHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(setModelUpdateHistory.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(setModelUpdateHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const getModelUpdateHistory = (state) => state.model.updateHistory;

export default modelSlice.reducer;
