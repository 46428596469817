import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {useCallback, useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PieChart } from '@mui/x-charts/PieChart';
import Box from '@mui/material/Box';
import { getChats, setChats } from '../../../slices/usage';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import { getUserBuilding } from '../../../slices/building';

const topicKeywords = {
  'Docs & Policies': ['document', 'policy', 'policies', 'standard', 'specific', 'operating', 'HR', 'human resources', 'paystub', 'vacation', 'dress code', 'approval', 'approve', 'attorney fees', 'section 8', 'requirements', 'incident report'],
  'Lease Issues': ['lease', 'termination', 'notice', 'vacating', 'income', 'notice', 'ntv', 'amenities', 'pet', 'pets', 'dog', 'dogs', 'animals', 'bankruptcy', 'resident',  'transfer', 'renewal'],
  'Maintenance': ['maintenance', 'broken', 'parking'],
  'New Residents': ['prospective', 'promotion', 'turnover', 'moving in', 'move in', 'inspection', 'insurance', 'income', 'applicant', 'ids', 'rental history', 'rent history', 'screening', 'prorated', 'deny', 'denied'],
  'Software': ['entrata', 'mri', 'yardi'],
  'Greetings and Hellos': ['yo', 'hi', 'hii', 'hey', 'hi!', 'join', 'JOIN']
};

// Function to preprocess messages
const preprocessText = (text) => {
  return text.replace(/[^a-zA-Z0-9\s]/g, '').toLowerCase();
};

// Function to categorize messages based on keywords
const categorizeMessage = (message, categories=topicKeywords) => {
  message = message.toLowerCase();
  for (const [topic, keywords] of Object.entries(categories)) {
    if (keywords.some(keyword => message.includes(keyword))) {
      return topic;
    }
  }
  return 'Other'; // Default category for messages that don't match any topic
};

const Section = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#31394d',
  height: '100%'
}));

const Categories = ({ refreshData }) => {
  const pieChartRef = useRef(null);
  const [isSliceSelected, setIsSliceSelected] = useState(false);
  const [highlightedItem, setHighLightedItem] = useState(null);
  const dispatch = useDispatch();
  const [pieData, setPieData]  = useState([]);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const { chats, loading, error } = useSelector(getChats);
  const building = useSelector(getUserBuilding);

  useEffect(() => {
    if (isSliceSelected) {
      return;
    }

    let topic;
    let cleanedMessage;
    const categorizedMessages = [];

    for (let i = 0; i < chats.length; i++) {
      const chat = chats[i];
      if (chat.message.role === 'user') {
        cleanedMessage = preprocessText(chat.message.content);
        topic = categorizeMessage(cleanedMessage, building.categories);
        categorizedMessages.push({ ...chat, topic });

        // Tag Assistant message
        if ((i + 1) < chats.length) {
          categorizedMessages.push({ ...chats[i + 1], topic })
        }
      }
    }

    // Count the number of instances per topic
    const topicCounts = categorizedMessages.reduce((counts, message) => {
      counts[message.topic] = (counts[message.topic] || 0) + 1;
      return counts;
    }, {});

    // Filter out the 'Greetings and Hellos' category from the topic counts
    delete topicCounts['Greetings and Hellos'];

    const data = Object.entries(topicCounts).map(([label, value]) => ({
      id: label,
      label,
      value,
    }));

    setSelectedMessages(categorizedMessages);
    setPieData(data);
  }, [chats, building]);

  const handleDocumentClick = useCallback((event) => {
    if (isSliceSelected && pieChartRef.current && !pieChartRef.current.contains(event.target)) {
      setHighLightedItem(null);
      setIsSliceSelected(false);
      refreshData();
    }
  }, [isSliceSelected, pieChartRef, refreshData]);

  useEffect(() => {
    if (!isSliceSelected) {
      return;
    }
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [handleDocumentClick, isSliceSelected]);

  const handlePieSliceClick = useCallback((_, d) => {
    const messages = selectedMessages.filter(m => m.topic === pieData[d.dataIndex].label);
    setIsSliceSelected(true);
    dispatch(setChats(messages));
    setHighLightedItem(d);
  }, [selectedMessages, pieData, dispatch]);

  return (
    <Section elevation={2}>
      {loading ? <CircularProgress style={{ margin: '150px 0' }} /> :
        error ?
          <Alert variant="filled" severity="error">{error}</Alert> :
          <Box>
            <h2 style={{
              textAlign: 'left',
              marginBottom: '0',
              padding: '0px 12px 0 12px',
              color: '#31394D'
            }}>Categories</h2>
            {pieData.length > 0 ?
              <div ref={pieChartRef}>
                <PieChart
                  ref={pieChartRef}
                  skipAnimation={false}
                  colors={['#7FB3D5', '#82C785', '#D5DBDB', '#AED6F1', '#A9DFBF', '#76D7C4', '#81b2c5', '#77DD77', '#B2D8D8', '#C4E8C2', '#D5E8E8', '#639FB6']}
                  series={[
                    {
                      data: pieData,
                      outerRadius: 100,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    },
                  ]}
                  onHighlightChange={(d) => !isSliceSelected && setHighLightedItem(d)}
                  highlightedItem={highlightedItem}
                  height={250}
                  slotProps={{
                    legend: {
                      itemMarkHeight: 15,
                      itemMarkWidth: 15,
                      labelStyle: {
                        fontSize: 14, // Change this value to the desired font size
                      },
                    },
                  }}
                  onItemClick={handlePieSliceClick}
                />
              </div> :
              <Chip label="No Chart Data" color="primary" variant="outlined" sx={{ marginTop: '15px' }} />
            }
          </Box>
      }
    </Section>
  );
};

export default Categories;
