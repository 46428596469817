import Header from '../../components/Header';
import { useState } from 'react';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Usage from './usage';
import { BASE_URL } from '../../app/config';
import { useSelector } from 'react-redux';
import { getUserAccessToken } from '../../slices/auth';
import Alert from "@mui/material/Alert";

const Section = styled(Paper)(({ theme }) => ({
  borderRadius: '12px',
  backgroundColor: 'white',
  padding: '14px 8px',
  textAlign: 'center',
  color: '#31394d',
  margin: '31px 34px',
  width: '800px'
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      { value === index && children }
    </div>
  );
}

const BillingPage = () => {
  const [error, setError] = useState(null)
  const accessToken =  useSelector(getUserAccessToken);
  const [value, setValue] = useState(0);

  const handleChange = async (event, newValue) => {
    if (newValue === 1) {
      try {
        const response = await fetch(`${BASE_URL}/billing/portal`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();

        window.location.href = data.url;
      } catch (e) {
        console.error(e);
        setError('Failed to redirect to manage billing. Please try again later.');
      }
    } else {
      setValue(newValue);
    }
  };

  return (
    <Grid className="main-container">
      <Header title="Billing" hideBuildingSelector />
      {error && <Alert variant="filled" severity="error">{error}</Alert>  }
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Section elevation={2}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="select billing tab">
              <Tab label="Overview" />
              <Tab label="Manage" />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Usage />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div>Redirecting...</div>
          </CustomTabPanel>
        </Section>
      </Grid>
    </Grid>
  );
};

export default BillingPage;
