import React, {useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteStaff,
  fetchStaff,
  getStaff,
  setStaffUser,
  updateStaffUser,
} from '../../../../slices/auth';
import { getUserBuilding } from '../../../../slices/building';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TableContainer,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { IMaskInput } from 'react-imask';
import SearchBar from '../../../../components/SearchBar';

const PhoneNumberMask = React.forwardRef(function PhoneNumberMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value, mask) => {
        onChange({ target: { name: props.name, value: mask.unmaskedValue } });
      }}
      overwrite
    />
  );
});

const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    width: '40vw',
  },
});

const StyledTableContainer = styled(TableContainer)({
  borderRadius: '8px',
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: 'pre-line',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.table.twoToneMain,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: theme.palette.table.twoToneSecondary,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Staff = () => {
  const dispatch = useDispatch();
  const [staff, setStaff] = useState([]);
  const [open, setOpen] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [tags, setTags] = useState({});
  const [userToEdit, setUserToEdit] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const initialStaff = useSelector(getStaff);
  const building = useSelector(getUserBuilding);

  const isPrivate = building.isPrivate;
  const staffExists = initialStaff.length > 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openEdit = (row) => {
    setUserToEdit(row);
    setEditUser(true);
  };

  const handleEdit = async () => {
    await dispatch(updateStaffUser(userToEdit));
    setEditUser(false);
  };

  const handleSave = async () => {
    await dispatch(
      setStaffUser({
        name,
        tags,
        phoneNumber: `1${phoneNumber}`,
      })
    );
    setOpen(false);
    setName('');
    setPhoneNumber('');
    dispatch(fetchStaff());
  };

  const handleDelete = async () => {
    await dispatch(deleteStaff(userToDelete.id));
    setUserToDelete(null);
  };

  const handleSearch = useCallback((query) => {
    const queryLower = query.trim().toLowerCase();

    if (!queryLower) {
      setStaff(initialStaff);
      return;
    }

    const filteredStaff = initialStaff.filter((s) => (
        s.name.toLowerCase().includes(queryLower) ||
        s.phoneNumber.toLowerCase().includes(queryLower) ||
        (s.tags?.buildingName && s.tags.buildingName.toLowerCase().includes(queryLower))
      )
    );

    setStaff(filteredStaff);
  }, [initialStaff]);

  useEffect(() => {
    dispatch(fetchStaff());
  }, [dispatch, building]);

  useEffect(() => {
    setStaff(initialStaff);
  }, [initialStaff]);

  if (!isPrivate) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <div>
        <div className="admins" style={{ paddingBottom: '24px' }}>Staff Users</div>
      </div>
      {staffExists && (
        <div>
          <StyledTableContainer>
            <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Name</StyledTableCell>
                  <StyledTableCell align="left">Building</StyledTableCell>
                  <StyledTableCell align="left">Number</StyledTableCell>
                  <StyledTableCell align="left">Actions</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left" colSpan={4}>
                    <SearchBar searchOnKeydown height={45} onSearch={handleSearch} style={{ width: '100%' }} />
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {staff.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                    <StyledTableCell align="left">{row.tags?.buildingName || 'Unknown'}</StyledTableCell>
                    <StyledTableCell align="left">{row.phoneNumber}</StyledTableCell>
                    <StyledTableCell align="left">
                      <IconButton size="small" color="error" aria-label="delete user">
                        <DeleteIcon size="small" onClick={() => setUserToDelete(row)} />
                      </IconButton>
                      <IconButton color="secondary" onClick={() => openEdit(row)}>
                        <EditIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={staff.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}

      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        style={{ float: 'right', marginTop: '24px' }}
        onClick={() => setOpen(true)}
      >
        Add Staff User
      </Button>

      <StyledDialog open={editUser} onClose={() => setEditUser(false)}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <AdminPanelSettingsIcon color="primary" sx={{ fontSize: '1.5em', marginRight: '5px' }} />Edit Staff User
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            disabled
            fullWidth
            value={userToEdit?.name}
          />
          <TextField
            margin="dense"
            label="Phone Number"
            type="text"
            disabled
            fullWidth
            value={userToEdit && userToEdit?.phoneNumber.slice(1)}
            InputProps={{
              inputComponent: PhoneNumberMask,
            }}
          />
          {building && building.tags &&
            Object.keys(building.tags).map(t => building.tags[t].type === 'select' && (
              <FormControl fullWidth margin="dense" key={t}>
                <InputLabel id="demo-simple-select-label">{building.tags[t].label}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={userToEdit?.tags ? userToEdit.tags[t] : ''}
                  label="Option"
                  onChange={(e) => setUserToEdit({
                    ...userToEdit,
                    tags: {
                      ...userToEdit.tags,
                      [t]: e.target.value,
                    },
                  })}
                >
                  {building.tags[t].options.map((o) => (
                    <MenuItem value={o} key={o}>{o}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ))
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditUser(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEdit} color="primary">
            Save
          </Button>
        </DialogActions>
      </StyledDialog>

      <StyledDialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <AdminPanelSettingsIcon color="primary" sx={{ fontSize: '1.5em', marginRight: '5px' }} />New Staff User
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Phone Number"
            type="text"
            fullWidth
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            InputProps={{
              inputComponent: PhoneNumberMask,
            }}
          />
          {building && building.tags &&
            Object.keys(building.tags).map(t => building.tags[t].type === 'select' && (
              <FormControl fullWidth margin="dense" key={t}>
                <InputLabel id="demo-simple-select-label">{building.tags[t].label}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  value={tags[t] || ''}
                  label="Option"
                  onChange={(e) => setTags({
                    ...tags,
                    [t]: e.target.value,
                  })}
                >
                  {building.tags[t].options.map((o) => (
                    <MenuItem value={o} key={o}>{o}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ))
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </StyledDialog>

      <Dialog open={!!userToDelete} onClose={() => setUserToDelete(false)}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <DeleteTwoToneIcon color="error" sx={{ fontSize: '1.5em', marginRight: '5px' }} />Delete Staff User
        </DialogTitle>
        <DialogContent>
          <p>
            Are you sure you would like to remove <b>{userToDelete?.name}</b> from <b>{building?.name}</b>? The user will not be able to access <b>{building?.number}</b> anymore.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUserToDelete(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Staff;
