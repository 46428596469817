import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import { getUserAccessToken } from '../../../slices/auth';
import { fetchBilling, getBillingData } from '../../../slices/billing';

const ErrorAlert = styled(Alert)({
  margin: '10px auto',
});

const PeriodTitle = styled('div')({
  textAlign: 'left',
  margin: '21px 7px',
  fontWeight: 500,
  fontSize: '21px'
});

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.primary.main,
  fontWeight: 600,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

const Usage = () => {
  const [chartData, setChartData] = useState([]);
  const dispatch = useDispatch();
  const accessToken =  useSelector(getUserAccessToken);
  const { usage: billingData, error } =  useSelector(getBillingData);
  const startDate = moment.unix(billingData?.periodStart).format('MMMM D, YYYY');
  const endDate = moment.unix(billingData?.periodEnd).format('MMMM D, YYYY');

  const formatCurrency = (amount) => {
    if (!amount || isNaN(amount) || !billingData?.currency) {
      return 0;
    }

    return new Intl.NumberFormat('en-US', { style: 'currency', currency:  billingData.currency }).format(amount / 100);
  };

  useEffect(() => {
    dispatch(fetchBilling());
  }, [accessToken, dispatch]);

  useEffect(() => {
    if (billingData.lines) {
      setChartData(billingData.lines.map(line => ({
        value: line.amount / 100,
        label: line.description
      })));
    }
  }, [billingData]);

  return (
    <div>
      {error && <ErrorAlert variant="filled" severity="error">{error}</ErrorAlert> }
      {!error &&
        <div>
          <PeriodTitle>{startDate} - {endDate}</PeriodTitle>
          <PieChart
            colors={['#AED6F1', '#82C785', '#7FB3D5', '#D5DBDB', '#A9DFBF', '#76D7C4', '#81b2c5', '#77DD77', '#B2D8D8', '#C4E8C2', '#D5E8E8', '#639FB6']}
            series={[{
              data: chartData,
              innerRadius: 90,
              highlightScope: { fade: 'global', highlight: 'item' },
            }]}
            slotProps={{
              legend: { hidden: true },
            }}
            height={275}
            sx={{ marginLeft: '93px' }}
          >
            <PieCenterLabel>${billingData?.amountDue / 100}</PieCenterLabel>
          </PieChart>
          <TableContainer sx={{ marginTop: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Per Unit</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {billingData?.lines?.map((line, index) => (
                  <TableRow key={index}>
                    <TableCell>{line.description}</TableCell>
                    <TableCell align="right">{line.perUnit ? line.quantity : 1}</TableCell>
                    <TableCell align="right">{line.perUnit !== null ? `$${line.perUnit}` : formatCurrency(line.amount)}</TableCell>
                    <TableCell align="right">{formatCurrency(line.amount)}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={3} align="right"><strong>Total</strong></TableCell>
                  <TableCell align="right">{formatCurrency(billingData?.amountDue)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      }
    </div>
  );
}

export default Usage;
