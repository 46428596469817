import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { LineChart } from '@mui/x-charts/LineChart';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getChats } from '../../../slices/usage';

const Section = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#31394d',
}));

function convertUsageToGraphData(dataArray, sDate, eDate) {
  // Create an object to store the counts for each day
  let yAxis = [];
  let xAxis = [];
  const dateCounts = {};
  let startDate = moment(sDate); // Using Moment.js for dates
  const endDate = moment(eDate);

  while (startDate <= endDate) {
    dateCounts[startDate.format('YYYY-MM-DD')] = 0;
    startDate = startDate.add(1, 'days');
  }

  // Count requests per day from your data
  dataArray.forEach(item => {
    const date = moment(item.timestamp).format('YYYY-MM-DD');
    dateCounts[date] += 1;
  });

 // Convert the dateCounts object into an array of objects
  Object.entries(dateCounts).forEach(([date, numberOfRequests]) => {
    yAxis.push(numberOfRequests);
    xAxis.push(moment(date).toDate());
  });

  return [xAxis, yAxis];
}

const valueFormatter = (date) =>
  moment(date).format("Do MMM")

const UsageGraph = ({ startDate, endDate }) => {
  const [processingLoading, setProcessingLoading] = useState(true);
  const [graphData, setGraphData] = useState([[new Date()], [1]]);
  const { chats, loading, error } = useSelector(getChats);

  useEffect(() => {
    if (chats) {
      setGraphData(convertUsageToGraphData(chats, startDate, endDate));
    }
  }, [chats])

  useEffect(() => {
    setProcessingLoading(false);
  }, [graphData]);

  return (
    <Section elevation={2}>
      {loading || processingLoading ? <CircularProgress style={{ margin: '150px 0' }} /> :
        error ?
          <Alert variant="filled" severity="error">{error}</Alert> :
          <div>
            <h2 style={{
              textAlign: 'left',
              marginLeft: '34px',
              marginBottom: '0',
              color: '#31394D'
            }}>Total Questions</h2>
            <LineChart
              xAxis={[{
                min: graphData[0][0],
                data: graphData[0],
                scaleType: 'time',
                valueFormatter,
                tickMinStep: 3600 * 1000 * 24,
                tickNumber: 8
              }]}
              series={[
                {
                  data: graphData[1],
                  showMark: ({ index }) => index % 2 === 0,
                },
              ]}
              height={300}
            />
          </div>
      }
    </Section>
  );
};

export default UsageGraph;
