import { configureStore } from '@reduxjs/toolkit';
import authSlice from '../slices/auth';
import buildingSlice from '../slices/building';
import billingSlice from '../slices/billing';
import usageSlice from '../slices/usage';
import escalationSlice from '../slices/escalation';
import documentSlice from '../slices/document';
import modelSlice from '../slices/model';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    building: buildingSlice,
    billing: billingSlice,
    usage: usageSlice,
    escalation: escalationSlice,
    document: documentSlice,
    model: modelSlice,
  }
});
