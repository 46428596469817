import { useState } from 'react';
import { Typography, Button } from '@mui/material';

const ExpandableText = ({ text, maxLength }) => {
  const [expanded, setExpanded] = useState(false);

  if (text.length <= maxLength) {
    return <Typography variant="body2" color="textSecondary">{text}</Typography>;
  }

  return (
    <Typography variant="body2" color="textSecondary">
      {expanded ? text : `${text.substring(0, maxLength)}...`}
      <Button
        onClick={() => setExpanded(!expanded)}
        size="small"
        style={{ textTransform: 'none', padding: 0, marginLeft: 4 }}
      >
        {expanded ? 'See less' : 'See more'}
      </Button>
    </Typography>
  );
};

export default ExpandableText;
