import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './components/AuthContext';
import PublicOnly from './components/PublicOnly';
import routes from './lib/routes';
import Sidebar from './components/Sidebar';
import MobileWarningDialog from "./components/MobileWarningDialog";

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, Arial, sans-serif',
  },
  palette: {
    primary: {
      main: '#689B5E',
    },
    secondary: {
      main: '#639FB6',
    },
    table: {
      main: '#E5E9F2',
      alternate: '#B8C5D3',
      twoToneMain: '#BFD8E1',
      twoToneSecondary: 'rgba(184, 197, 211, 0.28)',
    },
    log: {
      main: 'rgba(99, 159, 182, 0.20)',
      alternate: 'rgba(99, 159, 182, 0.45)'
    },
    pastelRed: {
      main: '#FF6961',
      light: '#f66e66',
      dark: '#FF3D33',
      contrastText: '#FFFFFF',
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
            <Sidebar />
            <MobileWarningDialog />
            <Routes>
              {routes.map((route, key) => (
                <Route key={key} path={route.path} element={
                  route.private ? <PrivateRoute>{route.component}</PrivateRoute> :
                    <PublicOnly>{route.component}</PublicOnly>
                }
                />
              ))}
            </Routes>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
