import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  Avatar,
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { BASE_URL } from '../../../app/config';
import { useSelector } from 'react-redux';
import { getUserBuilding } from '../../../slices/building';
import { getUserAccessToken } from '../../../slices/auth';

const HIO = 'Hio';

const Section = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#31394d',
}));

const initialConversation = [
  {
    message: 'Hello! How can I assist you today?',
    sentTime: 'just now',
    sender: HIO,
  }
];

const Chat = ({ setAnimate, setQuestion, setAnswer }) => {
  const building = useSelector(getUserBuilding);
  const accessToken =  useSelector(getUserAccessToken);
  const [messages, setMessages] = useState(initialConversation);
  const [generating, setGenerating] = useState(false);

  useEffect(() => {
    setMessages(initialConversation);
  }, [building]);

  const handleUpdate = (question, answer) => {
    setAnimate(true);
    setQuestion(question);
    setAnswer(answer);
  }

  const handleSend = async (_, text) => {
    setGenerating(true);

    const newMessage = {
      message: text,
      sentTime: 'just now', // May want to get the actual sent time from the server
      direction: 'outgoing',
      position: 'normal',
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    try {
      const response = await fetch(`${BASE_URL}/chat/web?text=${text}&to=${building.number}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }

      // Parse the response and update the state with the new message
      const message = await response.json();

      const receivedMessage = {
        message: message[0].text, // TODO: Why is this an array?
        sentTime: 'just now',
        sender: HIO,
      };

      setMessages((prevMessages) => [...prevMessages, receivedMessage]);
    } catch (error) {
      console.error('Error sending message:', error.message);
    }
    setGenerating(false);
  };
  return (
    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ padding: '25px' }}>
      <Grid item xs={12}>
        <Section elevation={2}>
          <div style={{ position: "relative", height: "44vh" }}>
            <MainContainer style={{ border: 'none' }}>
              <ChatContainer>
                <MessageList typingIndicator={generating && <TypingIndicator content="HIO is typing" />}>
                  {messages.map((msg, index) => (msg.sender === HIO && index !== 0 ?
                    <Message key={index} model={msg} avatarPosition="center-right">
                      <Avatar style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <IconButton aria-label="auto-fix" size="small" onClick={() =>
                          handleUpdate(messages[index - 1].message, msg.message)}
                        >
                          <AutoFixHighIcon fontSize="inherit" />
                        </IconButton>
                      </Avatar>
                    </Message> :
                    <Message key={index} model={msg} />
                  ))}
                </MessageList>
                <MessageInput placeholder="Type your message here" attachButton={false} onSend={handleSend} />
              </ChatContainer>
            </MainContainer>
          </div>
        </Section>
      </Grid>
    </Grid>
  );
};

export default Chat;
