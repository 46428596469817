import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery, useTheme } from '@mui/material';

function MobileWarningDialog() {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isMobile) {
      setOpen(true)
    }
  }, [isMobile]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} sx={{ width: '100vw', height: '100vh' }}>
      <DialogTitle>Warning: Mobile Device Detected</DialogTitle>
      <DialogContent>
        <p>
          Hey, it looks like you're using a mobile device. Please note that this website may not be optimized for mobile
          devices, so some features may not work as expected. Are you sure you want to continue?
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Yes, it's okay
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MobileWarningDialog;
