import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import '../../../statics/styles/pages/chat.scss';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchModelUpdateHistory, setModelUpdateHistory } from '../../../slices/model';

const Section = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#31394d',
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign: 'center'
};

const updateTypes = [{
  value: 'qna',
  label: 'Add A Question'
}, {
  value: 'statement',
  label: 'Add General Information'
}]

const Update = ({
  animate,
  question,
  answer,
  setQuestion,
  setAnswer
}) => {
  const dispatch = useDispatch();
  const [type, setType] =  useState(updateTypes[0].value);
  const [statement, setStatement] = useState('');
  const [open, setOpen] = useState(false);

  const setUpdate = async () => {
    try {
      await dispatch(setModelUpdateHistory({
        question,
        answer,
        statement
      }));
      dispatch(fetchModelUpdateHistory());
      setOpen(true)
    } catch (e) {
      console.error(e);
    }
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
    reset();
  };

  const reset = () => {
    setQuestion('');
    setAnswer('');
    setOpen(false);
  };
  return (
    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ padding: '25px' }}>
      <Grid item xs={4}>
        <Section elevation={2} className="update-model-info" sx={{ padding: '16px', height: '100%' }}>
          <div className="update-model-container">
            <div className="title">Update The Model</div>
            <div className="desc">Something missing? Use this module to add more information to your model’s knowledge base.  Updates may take up to 48 hours to process.  </div>
          </div>
        </Section>
      </Grid>
      <Grid item xs={8}>
        <Section className={`update-model-input-container ${animate ? 'shake-animation' : ''}`} elevation={2} sx={{ padding: '16px' }}>
          <FormControl sx={{ m: 1, minWidth: 120, margin: '0 0 10px 0px' }} size="small">
            <InputLabel id="demo-select-small-label">Type</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={type}
              label="Type"
              onChange={handleTypeChange}
            >
              {updateTypes.map(u => (
                <MenuItem value={u.value}>{u.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="update-box">
            {type === updateTypes[0].value &&
              <TextField
                id="standard-multiline-static"
                label="Potential Question"
                multiline
                placeholder="When is rent technically due?"
                rows={5}
                variant="filled"
                sx={{ width: '25%' }}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            }

            {type === updateTypes[0].value &&
              <TextField
                id="standard-multiline-static"
                label="Hio Answer"
                multiline
                placeholder="Rent is due 1st of every month."
                rows={5}
                variant="filled"
                sx={{ width: '62%' }}
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            }

            {type === updateTypes[1].value &&
              <TextField
                id="standard-multiline-static"
                label="New Model Information"
                multiline
                placeholder="Add the information you would like to be added."
                rows={5}
                variant="filled"
                sx={{ width: '90%' }}
                value={statement}
                onChange={(e) => setStatement(e.target.value)}
              />
            }

            <div>
              <Button variant="contained" size='small' endIcon={<SendIcon />} onClick={setUpdate}>
                Update
              </Button>
            </div>
          </div>
          <div className="update-note"><span className="bold">Note:</span> Our models use natural language processing to understand different ways users might ask a question, so there's no need for multiple versions.</div>
        </Section>
      </Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={reset}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconButton
              aria-label="close"
              onClick={reset}
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Thank you!
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Please allow 48 hours for the change to reflect in your model. You will be notified when your model is updated.
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </Grid>
  );
};

export default Update;
