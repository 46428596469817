import moment from 'moment/moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Parser } from '@json2csv/plainjs';
import { saveAs } from 'file-saver';

import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import IconButton from '@mui/material/IconButton';

import Header from '../../components/Header';
import '../../statics/styles/pages/analytics.scss';
import { fetchUsageData, getChats } from '../../slices/usage';
import { getUserBuilding } from '../../slices/building';
import SearchBar from '../../components/SearchBar';
import { paths } from '../../lib/routes';
import { useNavigate } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';

const Section = styled(Paper)({
  borderRadius: '12px',
  backgroundColor: 'white',
  padding: '14px 8px',
  textAlign: 'center',
  color: '#31394d',
  margin: '0px 34px',
  width: '94%',
});

const StyledDataGrid = styled(DataGrid)({
  border: 0,
  width: '100%',
  height: '67.8vh',
});

const style = {
  borderRadius: '12px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const downloadCSV = async (chats) => {
  const json2csvParser = new Parser({
    fields: [
      {
        label: 'role',
        value: 'message.role'
      },
      {
        label: 'name',
        value: 'user.name',
        default: 'Anonymous'
      },
      {
        label: 'message',
        value: 'message.content'
      },
      {
        label: 'user number',
        value: 'user.phoneNumber'
      },
      {
        label: 'timestamp',
        value: (c) => moment(c?.timestamp).format('MMMM Do YYYY, h:mm A'),
      },
    ]
  });
  const csv = json2csvParser.parse(chats);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, 'chat_history.csv');
};

const StyledSearchAndExport = styled('div')({
  display: 'flex',
  margin: '31px 34px',
  width: '94%',
  gap: '18px',
});

const StyledExportBtn = styled(Button)({
  fontSize: '15px',
  borderRadius: '28px',
  color: 'white',
  textTransform: 'none',
});

const ChatsPage = () => {
  const dispatch = useDispatch();
  const building = useSelector(getUserBuilding);
  const navigate = useNavigate();

  const [chatLog, setChatLog] = useState([]);
  const { chats, loading, error } = useSelector(getChats);

  const [endDate, setEndDate] = useState(moment());
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month'));

  const [viewRow, setViewRow] = useState(null);

  const handleReply = useCallback((phoneNumber) => {
    navigate(`${paths.BLAST}?number=${phoneNumber.replace(/\D/g, '')}`);
  }, [navigate]);

  const columns = [
    {
      field: 'dateTime',
      headerName: 'Date - Time',
      width: 200,
      valueFormatter: (value) => moment(value).format('MMMM Do YYYY, h:mm A'),
      filterable: false,
    },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'message', headerName: 'Message', flex: 4 },
    (building?.isPrivate ? { field: 'buildingName', headerName: 'Building', flex: 1 } : {}),
    { field: 'number', headerName: 'Number', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <IconButton aria-label="reply" color="secondary" onClick={() => handleReply(params.row.number)}>
          <ReplyIcon />
        </IconButton>
      )
    },
  ];

  useEffect(() => {
    if (building) {
      dispatch(fetchUsageData({ startDate: startDate.toDate(), endDate: endDate.toDate() }));
    }
  }, [dispatch, startDate, endDate, building]);

  useEffect(() => {
    const messages = [];
    for (let i = chats.length - 1; i >= 0; i--) {
      const userMessage = chats[i];
      messages.push({
        id: i,
        dateTime: userMessage.timestamp,
        message: userMessage.message.content,
        name: userMessage.user?.name || 'Anonymous',
        buildingName: userMessage.user?.tags?.buildingName || 'Unknown',
        number: userMessage.user?.phoneNumber || 'Unknown',
      });
    }

    setChatLog(messages);
  }, [chats]);

  const handleSearch = useCallback((query) => {
    const queryLower = query.toLowerCase();
    const regex = new RegExp(`\\b${queryLower}\\b`);

    const filteredChats = chats.filter(chat =>
      regex.test(chat.message.content.toLowerCase()) || // whole word search in message
      (chat.user?.name || 'Anonymous').toLowerCase().includes(queryLower) || // substring search in name
      (chat.user?.tags?.buildingName || 'Unknown').toLowerCase().includes(queryLower) || // substring search in name
      (chat.user?.phoneNumber || 'Unknown').toLowerCase().includes(queryLower)
    );

    const messages = filteredChats.map((chat, index) => ({
      id: index,
      dateTime: chat.timestamp,
      message: chat.message.content,
      name: chat.user?.name || 'Anonymous',
      buildingName: chat.user?.tags?.buildingName || 'Unknown',
      number: chat.user?.phoneNumber || 'Unknown',
    }));

    setChatLog(messages);
  }, [chats]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid className="main-container">
        <div className="analytics-header">
          <Header title="Chats" leftAlign />
          <div>
            <DatePicker
              sx={{ marginRight: '14px' }}
              label="Start"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
            />
            <DatePicker
              sx={{ marginRight: '25px' }}
              label="End"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </div>
        </div>
        <Grid container justifyContent="center" alignItems="center">
          <StyledSearchAndExport>
            <SearchBar onSearch={handleSearch} />
            <StyledExportBtn startIcon={<CloudDownloadIcon />} onClick={() => downloadCSV(chats)} color="secondary" variant="contained">
              Export Table
            </StyledExportBtn>
          </StyledSearchAndExport>
          <Section elevation={2}>
            <StyledDataGrid rows={chatLog} columns={columns} autoPageSize onRowDoubleClick={(r)=> setViewRow(r.row)} />
          </Section>
        </Grid>
      </Grid>
      <Modal
        open={!!viewRow}
        onClose={() => setViewRow(null)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {moment(viewRow?.dateTime).format('MMMM Do YYYY, h:mm A')}
          </Typography>
          {building?.isPrivate  &&
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {viewRow?.buildingName}
            </Typography>
          }
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {viewRow?.name} ({viewRow?.number}):
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            {viewRow?.message}
          </Typography>
        </Box>
      </Modal>
    </LocalizationProvider>
  );
};

export default ChatsPage;
