import { memo, useEffect, useState } from 'react';

const AnalyticsIcon = ({ isSelected, ...props }) => {
  const [fill, setFill] = useState('#545F69');

  useEffect(() => {
    setFill(isSelected ? 'white' : '#545F69');
  }, [isSelected]);

  const handleMouseEnter = () => {
    if (!isSelected) {
      setFill('white');
    }
  };

  const handleMouseLeave = () => {
    if (!isSelected) {
      setFill('#545F69');
    }
  };
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...props}>
      <path
        d="M4.24506 18.5H5.63069V15H4.24506V18.5ZM6.61028 20.5H3.26547C2.72424 20.5 2.28587 20.055 2.28587 19.5V14C2.28587 13.45 2.72424 13 3.26547 13H6.61028C7.15102 13 7.58987 13.45 7.58987 14V19.5C7.58987 20.055 7.15102 20.5 6.61028 20.5Z"
        fill={fill}/>
      <path
        d="M11.3887 18.5H12.7744V8.5H11.3887V18.5ZM13.754 20.5H10.4091C9.86841 20.5 9.42955 20.055 9.42955 19.5V7.5C9.42955 6.95 9.86841 6.5 10.4091 6.5H13.754C14.2952 6.5 14.7336 6.95 14.7336 7.5V19.5C14.7336 20.055 14.2952 20.5 13.754 20.5Z"
        fill={fill}/>
      <path
        d="M18.5329 18.5H19.9185V2H18.5329V18.5ZM20.8981 20.5H17.5533C17.0126 20.5 16.5737 20.055 16.5737 19.5V1C16.5737 0.45 17.0126 0 17.5533 0H20.8981C21.4389 0 21.8777 0.45 21.8777 1V19.5C21.8777 20.055 21.4389 20.5 20.8981 20.5Z"
        fill={fill}/>
      <path
        d="M22.8573 24H1.30628C0.765548 24 0.326691 23.555 0.326691 23C0.326691 22.45 0.765548 22 1.30628 22H22.8573C23.398 22 23.8369 22.45 23.8369 23C23.8369 23.555 23.398 24 22.8573 24Z"
        fill={fill}/>
    </svg>
  );
};
export default memo(AnalyticsIcon);

