import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import { useSelector } from 'react-redux';
import { getUserBuildings } from '../../../slices/building';
const formatAddress = (address) => address ? `${address.line1},
  ${address.line2},
  ${address.city},
  ${address.state} ${address.zipCode}` : 'Unavailable';

const StyledTableContainer = styled(TableContainer)({
  borderRadius: '8px',
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: 'pre-line',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.table.twoToneMain,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: theme.palette.table.twoToneSecondary,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Buildings = () => {
  const buildings = useSelector(getUserBuildings);
  return (
    <div className="service">
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <div className="building">Buildings Registered</div>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '10px' }}>
        </Grid>
      </Grid>
      <StyledTableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Building Name</StyledTableCell>
              <StyledTableCell align="left">Public</StyledTableCell>
              <StyledTableCell align="left">Channels</StyledTableCell>
              <StyledTableCell align="left">Phone Number</StyledTableCell>
              <StyledTableCell align="left">Address</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {buildings.map((building) => (
              <StyledTableRow key={building.id}>
                <StyledTableCell align="left">{building.name}</StyledTableCell>
                <StyledTableCell align="left">{building.isPrivate ? 'No' : 'Yes'}</StyledTableCell>
                <StyledTableCell align="left">Web / Phone</StyledTableCell>
                <StyledTableCell align="left">{building.number}</StyledTableCell>
                <StyledTableCell align="left">{formatAddress(building.address)}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </div>
  );
}

export default Buildings;
