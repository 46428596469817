import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../app/config';

const initialState = {
  usage: {},
  error: null,
  loading: false,
};

export const fetchBilling = createAsyncThunk('service/fetchBilling', async (_, { getState, rejectWithValue }) => {
  const state = getState();
  const accessToken = state.auth?.accessToken;

  if (!accessToken) {
    return {};
  }

  const response = await fetch(`${BASE_URL}/billing/usage`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    return rejectWithValue({ status: response.status, statusText: response.statusText });
  }

  return await response.json();
});

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBilling.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBilling.fulfilled, (state, action) => {
        state.loading = false;
        state.usage = action.payload;
        state.error = null;
      })
      .addCase(fetchBilling.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const getBillingData = (state) => state.billing;

export default billingSlice.reducer;
