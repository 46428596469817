import { Grid } from '@mui/material';
import Header from '../../components/Header';
import SendBlast from './send';
import HistoryBlast from "./history";

const BlastPage = () => {
  return (
    <Grid className="main-container">
      <Header title="Messaging"/>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ padding: '38px 100px;' }}>
        <Grid container justifyContent="center" item xs={12}>
          <SendBlast />
        </Grid>
        <Grid container justifyContent="center" item xs={12}>
          <HistoryBlast />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BlastPage;
