import React, { useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';

import { styled } from '@mui/material/styles';

import {
  useDispatch,
  useSelector
} from 'react-redux';

import {
  fetchAdmins,
  getAdmins,
} from '../../../slices/auth';

import { getUserBuilding } from '../../../slices/building';
import Staff from './staff';


const StyledTableContainer = styled(TableContainer)({
  borderRadius: '8px',
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: 'pre-line',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.table.twoToneMain,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: theme.palette.table.twoToneSecondary,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Access = () => {
  const dispatch = useDispatch();

  const building = useSelector(getUserBuilding);
  const admins = useSelector(getAdmins);

  useEffect(() => {
    dispatch(fetchAdmins());
  }, [dispatch, building]);

  return (
    <div className="user">
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <div className="admins">Administrators</div>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '10px' }}>
          <StyledTableContainer>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Name</StyledTableCell>
                  <StyledTableCell align="left">Email</StyledTableCell>
                  <StyledTableCell align="left">Role</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {admins.map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                    <StyledTableCell align="left">{row.email}</StyledTableCell>
                    <StyledTableCell align="left">Super Admin</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>
        <Staff />
      </Grid>
    </div>
  );
}

export default Access;
