import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {useCallback, useEffect, useState} from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { fetchEscalationData } from '../../../slices/escalation';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Chip from "@mui/material/Chip";
import Grow from '@mui/material/Grow';
import { getUserBuilding } from '../../../slices/building';
import IconButton from '@mui/material/IconButton';
import ReplyIcon from '@mui/icons-material/Reply';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {paths} from "../../../lib/routes";
import {useNavigate} from "react-router-dom";

const Section = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#31394d',
  maxHeight: '41vh',
  overflowY: 'auto'
}));

const EscalationList = ({ startDate, endDate }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const building = useSelector(getUserBuilding);
  const { escalations, loading, error } = useSelector((state) => state.escalation);
  const [clickedItem, setClickedItem] = useState(null);

  useEffect(() => {
    dispatch(fetchEscalationData({ startDate, endDate }));
  }, [dispatch, startDate, endDate, building]);

  const handleItemClick = (index) => {
    setClickedItem(index);
  };

  const handleFix = useCallback(({ escalation }) => {
    navigate(`${paths.CONVERSATIONS}?question=${encodeURIComponent(escalation)}&answer=${encodeURIComponent('What should HIO say?')}`);
  }, [navigate]);

  const handleReply = useCallback(({ userPhone }) => {
    navigate(`${paths.BLAST}?number=${userPhone?.standard?.replace(/\D/g, '')}`);
  }, [navigate]);

  return (
    <Section elevation={2}>
      {loading ? <CircularProgress style={{ margin: '150px 0' }} /> :
        error ?
          <Alert variant="filled" severity="error">{error}</Alert> :
          <div>
            <div className="center-and-spaced-container" style={{ margin: '0 16px' }}>
              <h2 style={{
                textAlign: 'left',
                color: '#31394D'
              }}>Escalations</h2>
              <p style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '16px' }}>{escalations.length} escalations</p>
            </div>
            {escalations.length === 0 ?
              <Chip label="No escalations" color="primary" variant="outlined" style={{ marginBottom: '14px' }} /> :
              <List style={{ paddingTop: 0 }}>
                {escalations.map((e, index) => (
                  <ListItemButton
                    disableRipple
                    onClick={() => handleItemClick(index)}
                    sx={{
                      backdropFilter: clickedItem === index ? 'blur(4px)' : 'none',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: '80px', filter: clickedItem === index ? 'blur(4px)' : 'none', transition: '0.3s filter ease' }}>
                      <ListItemText primary={moment(e.timestamp.toDate()).format("Do MMM")} />
                    </ListItemIcon>
                    <ListItemText sx={{ filter: clickedItem === index ? 'blur(4px)' : 'none', transition: '0.3s filter ease' }} primary={e.escalation} />

                    <Grow in={clickedItem === index} timeout={500}>
                      <div
                        style={{
                          display: 'flex',
                          gap: '30px',
                          position: 'absolute',
                          width: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <IconButton color="primary" size="large" onClick={() => handleFix(e)}>
                          <AutoFixHighIcon />
                        </IconButton>
                        <IconButton color="secondary" size="large" onClick={() => handleReply(e)}>
                          <ReplyIcon />
                        </IconButton>
                      </div>
                    </Grow>
                  </ListItemButton>
                ))}
              </List>
            }
          </div>
      }
    </Section>
  );
};

export default EscalationList;
