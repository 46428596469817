import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import '../../statics/styles/pages/analytics.scss'
import Header from '../../components/Header';
import UsageGraph from './usage';
import { fetchUsageData } from '../../slices/usage';
import { useDispatch, useSelector } from 'react-redux';
import EscalationList from './escalation';
import { getUserBuilding } from '../../slices/building';
import Categories from './categories';
import ChatLog from './log';

const AnalyticsPage = () => {
  const dispatch = useDispatch();
  const building = useSelector(getUserBuilding);
  const [refresh, setRefresh] = useState(0);
  const [endDate, setEndDate] = useState(
    moment()
  );
  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'month')
  );

  const refreshData  = () => {
    setRefresh(Math.random());
  }

  useEffect(() => {
    if (building) {
      dispatch(fetchUsageData({ startDate: startDate.toDate(), endDate: endDate.toDate() }));
    }
  }, [dispatch, startDate, endDate, building, refresh]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="main-container">
        <div className="analytics-header">
          <Header title="Analytics" leftAlign />
          <div>
            <DatePicker
              sx={{ marginRight: '14px' }}
              label="Start"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
            />
            <DatePicker
              sx={{ marginRight: '25px' }}
              label="End"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </div>
        </div>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ padding: '25px' }}>
          <Grid item xs={8}>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12}>
                <UsageGraph startDate={startDate.toDate()} endDate={endDate.toDate()} />
              </Grid>
              <Grid item xs={6}>
                <EscalationList startDate={startDate.toDate()} endDate={endDate.toDate()} />
              </Grid>
              <Grid item xs={6}>
                {/*<CalendarHeatmap startDate={startDate.toDate()} endDate={endDate.toDate()} usage={usage} loading={loading} error={error} />*/}
                <Categories refreshData={refreshData} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12}>
                <ChatLog />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </LocalizationProvider>
  );
};

export default AnalyticsPage;
