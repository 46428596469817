import { memo, useEffect, useState } from 'react';

const BillingIcon = ({ isSelected, ...props }) => {
  const [fill, setFill] = useState('#545F69');

  useEffect(() => {
    setFill(isSelected ? 'white' : '#545F69');
  }, [isSelected]);

  const handleMouseEnter = () => {
    if (!isSelected) {
      setFill('white');
    }
  };

  const handleMouseLeave = () => {
    if (!isSelected) {
      setFill('#545F69');
    }
  };
  return (
    <svg viewBox="0 0 24 24" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...props}>
      <path d="M19 14V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2m-2 0H3V6h14zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3m13 0v11c0 1.1-.9 2-2 2H4v-2h17V7z" fill={fill}></path>
    </svg>
  );
};
export default memo(BillingIcon);

