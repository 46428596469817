import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  collectionGroup,
  where,
  query,
  getDocs,
  doc,
  collection,
  serverTimestamp,
  addDoc,
  Timestamp,
  orderBy
} from 'firebase/firestore';
import { db } from '../app/firebase';

export const fetchUsageData = createAsyncThunk('usage/fetchUsageData', async ({ startDate, endDate }, { getState, dispatch }) => {
  let chats = [];
  let state = getState(); // Access the global state
  let building = state.building.currentBuilding;

  if (!building) {
    throw new Error('No buildings linked');
  }

  const historyQuery = query(
    collectionGroup(db, 'chatHistory'),
    where('buildingPhone', '==', building.number),
    where('timestamp', '>=', startDate),
    where('timestamp', '<=', endDate)
  );

  const historyQuerySnapshot = await getDocs(historyQuery)

  historyQuerySnapshot.forEach((histChat) => {
    chats.push({ ...histChat.data(), userId: histChat.ref.parent.parent.id });
  });

  chats = chats.map(c => ({ ...c, timestamp: c.timestamp.toDate() }));

  return chats;
});

export const addBlastText = createAsyncThunk(
  'usage/addBlastText',
  async (payload, { getState }) => {
    const { building: { organization, currentBuilding: { id: buildingId } }, auth: { user } } = getState();
    const connectionRef = doc(db, 'organization', organization.id, 'building', buildingId);
    const blastRef = collection(connectionRef, 'blast');

    const data = {
      ...payload,
      timestamp: serverTimestamp(),
      author: user?.displayName ?? 'Unknown User',
      status: 'INITIATED',
    };

    await addDoc(blastRef, data);
    return {
      ...data,
      timestamp: Timestamp.now().toMillis(),
    };
  });

export const fetchBlastTexts = createAsyncThunk('usage/fetchBlastTexts', async (_, { getState }) => {
  const history = [];
  const { building: { organization, currentBuilding: { id: buildingId } } } = getState();
  const blastHistoryQuery = query(
    collection(db,'organization', organization.id, 'building', buildingId, 'blast'),
    orderBy('timestamp', 'desc')
  );
  const blastHistoryRef =  await getDocs(blastHistoryQuery);

  blastHistoryRef.forEach(d => history.push({ id: d.id, ...d.data() }));

  return history;
});

const usageSlice = createSlice({
  name: 'usage',
  initialState: { chats: [], blast: [], loading: true, error: null },
  reducers: {
    setChats: (state, action) => {
      state.chats = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsageData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsageData.fulfilled, (state, action) => {
        state.loading = false;
        state.chats = action.payload;
        state.error = null;
      })
      .addCase(fetchUsageData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addBlastText.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(addBlastText.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchBlastTexts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBlastTexts.fulfilled, (state, action) => {
        state.loading = false;
        state.blast = action.payload;
        state.error = null;
      })
      .addCase(fetchBlastTexts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setChats } = usageSlice.actions;

export const getChats = (state) => state.usage;

export const getBlastTexts = (state) => state.usage.blast;

export default usageSlice.reducer;
