import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import SmsTwoToneIcon from '@mui/icons-material/SmsTwoTone';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Autocomplete from '@mui/material/Autocomplete';

import { useDispatch, useSelector } from 'react-redux';

import { fetchStaff, fetchUsers, getStaff, getUsers } from '../../../slices/auth';
import { getUserBuilding } from '../../../slices/building';
import { addBlastText, fetchBlastTexts } from '../../../slices/usage';
import useQuery from '../../../lib/useQuery';
import Alert from "@mui/material/Alert";

const Section = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '12px',
  backgroundColor: 'white',
  padding: '19px 37px',
  textAlign: 'center',
  color: '#31394d',
  width: '900px'
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textAlign: 'start',
  fontSize: '1.2rem',
  fontWeight: 600
}));

function mergeUsersAndStaff(users, staff) {
  const phoneMap = new Map();

  function addToMap(arr, includeName = false) {
    arr.forEach(item => {
      const phoneNumber = item.phoneNumber;
      if (!phoneMap.has(phoneNumber)) {
        const newItem = { phoneNumber };
        if (includeName) {
          newItem.name = item.name;
        }
        phoneMap.set(phoneNumber, newItem);
      }
    });
  }

  addToMap(staff, true);
  addToMap(users);

  return Array.from(phoneMap.values());
}

const SendBlast = () => {
  const query = useQuery();
  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [blastUsers, setBlastUsers] = useState([]);
  const [recipient, setRecipient] = useState('all');
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const staff = useSelector(getStaff);
  const users = useSelector(getUsers);
  const building = useSelector(getUserBuilding);

  useEffect(() => {
    const number = query.get('number');

    if (number && blastUsers.length > 0) {
      const userFound = blastUsers.find(u => u.phoneNumber === number);
      if (userFound) {
        setRecipient('specific');
        setSelectedUsers([{
          ...userFound,
          label: userFound.name ? `${userFound.name} (${userFound.phoneNumber})` : userFound.phoneNumber,
        }])
      } else {
        setError('Invalid Number');
      }
    }
    // You can add additional logic here to handle query param changes
  }, [query, blastUsers]);

  useEffect(() => {
    dispatch(fetchStaff());
    dispatch(fetchUsers());
  }, [dispatch, building]);

  useEffect(() => {
    setBlastUsers(mergeUsersAndStaff(users, staff));
  }, [users, staff]);

  const handleRecipientChange = (event) => {
    setRecipient(event.target.value);
  };

  const handleSendText = async () => {
    let blast;
    if (recipient === 'specific') {
      blast = { recipients: selectedUsers, isAll: false, message };
    } else {
      blast = { recipients: null, isAll: true, message };
    }
    await dispatch(addBlastText(blast));
    await dispatch(fetchBlastTexts());
    setConfirmationDialog(false);
    setMessage('');
    setSelectedUsers([]);
  };

  const selectUsers = (_, users) => {
    setSelectedUsers(users);
  }

  return (
    <Section elevation={2}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 500 }}>
        Message Users
      </Typography>
      {error && <Alert variant="filled" severity="error" sx={{ margin: '17px 0px' }}>{error}</Alert>}
      <FormControl component="fieldset">
        <StyledFormLabel component="legend">Recipients:</StyledFormLabel>
        <RadioGroup
          aria-label="recipients"
          name="recipients"
          value={recipient}
          onChange={handleRecipientChange}
        >
          <FormControlLabel
            value="all"
            control={<Radio />}
            label={`All users of ${building?.name} (${building?.number})`}
          />
          <FormControlLabel
            value="specific"
            control={<Radio />}
            label="Specific users:"
          />
        </RadioGroup>
        {recipient === 'specific' && (
          <Autocomplete
            multiple
            id="combo-users"
            options={blastUsers.map((s) => ({ label: s.name ? `${s.name} (${s.phoneNumber})` : s.phoneNumber, ...s }))}
            isOptionEqualToValue={(option, value) => option.phoneNumber === value.phoneNumber}
            value={selectedUsers}
            onChange={selectUsers}
            renderInput={(params) => (
                <TextField
                  {...params}
                  label="User"
                  placeholder="Select Users"
                />
              )
            }
          />
        )}
      </FormControl>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        multiline
        rows={4}
        label="Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <div>
        <Button
          disabled={(recipient === 'specific' ? selectedUsers.length <= 0 : blastUsers.length <= 0) || !message}
          variant="contained"
          color="primary"
          onClick={() => setConfirmationDialog(true)}
        >
          Send Text {recipient === 'all' && `To ${blastUsers.length} Users`}
        </Button>
      </div>
      <Dialog open={confirmationDialog} onClose={() => setConfirmationDialog(false)}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <SmsTwoToneIcon color="primary" sx={{ fontSize: '1.5em', marginRight: '5px' }} />Send Text</DialogTitle>
        <DialogContent>
          <p>Are you sure you would like to text {recipient === 'specific' ? selectedUsers.length : blastUsers.length} user(s)?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationDialog(false)} color="error">
            Cancel
          </Button>
          <Button onClick={handleSendText} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </Section>
  );
};

export default SendBlast;
