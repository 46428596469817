import { memo, useEffect, useState } from 'react';

const FilesIcons = ({ isSelected, ...props }) => {
  const [fill, setFill] = useState('#545F69');

  useEffect(() => {
    setFill(isSelected ? 'white' : '#545F69');
  }, [isSelected]);

  const handleMouseEnter = () => {
    if (!isSelected) {
      setFill('white');
    }
  };

  const handleMouseLeave = () => {
    if (!isSelected) {
      setFill('#545F69');
    }
  };
  return (
    <svg viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M5.83784 17.6327V1.95918H10.7027V5.88637C10.7027 6.42759 11.1386 6.86596 11.6757 6.86596H15.5676V17.6327H5.83784ZM12.1622 21.551H1.94595V5.87755H3.89189V18.6122C3.89189 19.1535 4.32778 19.5918 4.86486 19.5918H12.1622V21.551ZM12.6486 3.34482L14.2001 4.90678H12.6486V3.34482ZM17.2284 5.18498L12.3636 0.28702C12.1811 0.103347 11.9335 0 11.6757 0H4.86486C4.32778 0 3.89189 0.438857 3.89189 0.979592V3.91837H0.972973C0.435892 3.91837 0 4.35722 0 4.89796V22.5306C0 23.0718 0.435892 23.5102 0.972973 23.5102H13.1351C13.6722 23.5102 14.1081 23.0718 14.1081 22.5306V19.5918H16.5405C17.0776 19.5918 17.5135 19.1535 17.5135 18.6122V5.87755C17.5135 5.61796 17.4109 5.36865 17.2284 5.18498Z"
            fill={fill}/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8.27027 10.0408H10.7027C11.106 10.0408 11.4324 9.71218 11.4324 9.30614C11.4324 8.90058 11.106 8.57144 10.7027 8.57144H8.27027C7.86698 8.57144 7.54054 8.90058 7.54054 9.30614C7.54054 9.71218 7.86698 10.0408 8.27027 10.0408Z"
            fill={fill}/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M13.1351 11.5102H8.27027C7.86698 11.5102 7.54054 11.8393 7.54054 12.2449C7.54054 12.6509 7.86698 12.9796 8.27027 12.9796H13.1351C13.5384 12.9796 13.8649 12.6509 13.8649 12.2449C13.8649 11.8393 13.5384 11.5102 13.1351 11.5102Z"
            fill={fill}/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M13.1351 14.449H8.27027C7.86698 14.449 7.54054 14.7781 7.54054 15.1837C7.54054 15.5897 7.86698 15.9184 8.27027 15.9184H13.1351C13.5384 15.9184 13.8649 15.5897 13.8649 15.1837C13.8649 14.7781 13.5384 14.449 13.1351 14.449Z"
            fill={fill}/>
    </svg>
  );
};

export default memo(FilesIcons);

