import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import GoogleButton from 'react-google-button'
import validator from 'validator';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../statics/styles/pages/login.scss';
import { useAuth } from '../../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../lib/routes';
import HioComputer from '../../statics/images/hio-computer.png';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { Button, Divider, Grid, Paper, TextField, Typography, InputAdornment, IconButton } from '@mui/material';
import Navbar from '../../components/Navbar';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signIn, signInWithGoogle, resetEmailPassword } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [resetPasswordStatus, setResetPasswordStatus] =   useState(false);
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (_, newIndex) => setCurrentSlide(newIndex),
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleResetPassword = async () => {
    setEmailError('');

    if (!validator.isEmail(email)) {
      setEmailError('Please enter a valid email address');
      return;
    }

    try {
      await resetEmailPassword(email)
      setResetPasswordStatus(true);
      sliderRef.current.slickGoTo(0);
    } catch (e) {
      if (e.code && e.code.includes('user-not-found')) {
        setEmailError('The user does not exist. Please contact connect@heyhio.com.')
      } else {
        console.error(e);
        setEmailError(e.message);
      }
    }
  }

  const handleLogin = async (e) => {
    e.preventDefault();

    setEmailError('');
    setPasswordError('');

    if (!validator.isEmail(email)) {
      setEmailError('Please enter a valid email address');
      return;
    }

    if (password.trim() === '') {
      setPasswordError('Please enter a password');
      return;
    }

    try {
      await signIn(email, password);
      navigate(paths.HOME);
    } catch (e) {
      if (e.code === 'auth/access-not-found') {
        setEmailError('Incorrect credentials.')
      } else {
        setEmailError('There was an unknown error with your request.')
      }
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithGoogle()
    } catch (e) {
      console.error(e);
    }
  }

  const handleKeyDown = (event) => {
    const isEnterKey = event.key === 'Enter' || event.keyCode === 13;
    if (isEnterKey) {
      if (currentSlide === 0) {
        handleLogin(event);
      } else if (currentSlide === 1) {
        handleResetPassword();
      }
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh', background: 'linear-gradient(90deg, rgba(99,159,182,1) 0%, rgba(104,155,94,1) 88%)' }}>
      <Navbar />
      <Paper elevation={16} square={false} style={{ width: '60vw', borderRadius: '19px', padding: '22px 0' }}>
        <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
          <Grid container justifyContent="center" alignItems="center" item xs={6} sm={6} style={{
            height: '100%',
            backgroundColor: '#F2F8FC',
            borderTopLeftRadius: '19px',
            borderBottomLeftRadius: '19px',
          }}>
            <img src={HioComputer} style={{ width: '86%' }} />
          </Grid>

          <Grid item xs={6} sm={6} style={{ padding: '0 12px' }}>
            <div className="slider-container">
              <Slider ref={sliderRef} {...settings}>
                <Grid item xs={12} sm={12}>
                  {resetPasswordStatus &&
                    <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                      A link was sent to your email at {email}. Please follow the link to reset your password.
                    </Alert>
                  }
                  <Typography variant="h5" gutterBottom style={{ fontWeight: 900 }} color='primary'>
                    Welcome to HIO
                  </Typography>
                  <Typography variant="p" gutterBottom>
                    To continue, please login.
                  </Typography>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={e => setEmail(e.target.value.trim())}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={!!emailError}
                    helperText={emailError}
                    onKeyDown={handleKeyDown}
                  />
                  <TextField
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <KeyIcon/>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={!!passwordError}
                    helperText={passwordError}
                    onKeyDown={handleKeyDown}
                  />

                  <a href="#" onClick={() => sliderRef.current.slickGoTo(1)} style={{ display: 'block', textAlign: 'right', fontSize: '11px', color: '#1976d2', textDecoration: 'none' }}>
                    Forgot your password?
                  </a>

                  <Button variant="contained" color="primary" fullWidth size="large" style={{ fontWeight: 900, boxShadow: '0 4px 9px -4px #689B5E', margin: '20px 0' }} onClick={handleLogin}>
                    Login
                  </Button>

                  <Divider sx={{ '& .MuiDivider-wrapper': { fontWeight: 900, color: '#757575' } }}>OR</Divider>

                  <GoogleButton
                    className="google-btn"
                    type="light"
                    onClick={handleGoogleLogin}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h5" gutterBottom style={{ fontWeight: 900 }} color='primary'>
                    Reset your password
                  </Typography>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={e => setEmail(e.target.value.trim())}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={!!emailError}
                    helperText={emailError}
                    onKeyDown={handleKeyDown}
                  />
                  <Button variant="contained" color="primary" fullWidth size="large" style={{ fontWeight: 900, boxShadow: '0 4px 9px -4px #689B5E', margin: '20px 0' }} onClick={handleResetPassword}>
                    Reset Password
                  </Button>
                </Grid>
              </Slider>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default LoginPage;
