import { createContext, useContext, useEffect } from 'react';
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithRedirect,
  GoogleAuthProvider,
  getRedirectResult,
  setPersistence,
  browserSessionPersistence,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { setUser, setUserAccessToken, setUserClaims } from '../slices/auth';
import { auth } from '../app/firebase';
import { useNavigate } from 'react-router-dom';
import  { paths } from '../lib/routes';
import { setUserBuildings } from "../slices/building";

const UserContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signIn = (email, password) =>  {
    return signInWithEmailAndPassword(auth, email, password)
  }

  const resetEmailPassword = (email) => {
    return sendPasswordResetEmail(auth, email, {
      url: 'https://dashboard.heyhio.com'
    });
  }

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    try {
      await setPersistence(auth, browserSessionPersistence);
      await signInWithRedirect(auth, provider);
    } catch (error) {
      console.error('Error during Google sign-in:', error);
    }
  }

  const logout = () => {
    signOut(auth).then(() => navigate(paths.LOGIN));
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      dispatch(setUser(currentUser && currentUser.toJSON()));
      if (currentUser) {
        const accessToken = await currentUser.getIdToken();
        const claims = (await currentUser.getIdTokenResult()).claims;
        dispatch(setUserClaims(claims));
        dispatch(setUserAccessToken(accessToken));
        await dispatch(setUserBuildings());
      }
    });

    getRedirectResult(auth)
      .then(() => {
        console.log('Logged in');
      })
      .catch((error) => {
        console.error('Error after redirecting back from Google sign-in: ', error);
      });

    return unsubscribe;
  }, [dispatch]);

  return (
    <UserContext.Provider value={{
      logout,
      signIn,
      signInWithGoogle,
      resetEmailPassword,
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(UserContext);
};
