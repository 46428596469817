import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import '../../statics/styles/pages/thermostat.scss'
import Header from '../../components/Header';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useEffect, useState } from "react";
import { mode } from './controls';
import ThermostatControls from "./controls";

const defaultStatusFilter = 'All';
const vacantStatus = 'vacant';

const randomNum = (min, max) => (Math.floor(Math.random() * (max - min + 1)) + min);

const Section = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#31394d',
}));

function createData(unitNumber, temperature, humidity, mode, unitFloor, status) {
  return { unitNumber, temperature, humidity, mode, unitFloor, status };
}

const rows = [
  createData('008', randomNum(55, 85), randomNum(30, 80), mode[0], 4, 'Vacant'),
  createData('101', randomNum(55, 85), randomNum(30, 80), mode[0], 2, 'Occupied'),
  createData('205', randomNum(55, 85), randomNum(30, 80), mode[2], 3, 'Vacant'),
  createData('306', randomNum(55, 85), randomNum(30, 80), mode[0], 5, 'Occupied'),
  createData('412', randomNum(55, 85), randomNum(30, 80), mode[1], 1, 'Vacant'),
  createData('513', randomNum(55, 85), randomNum(30, 80), mode[0], 2, 'Occupied'),
  createData('554', randomNum(55, 85), randomNum(30, 80), mode[2], 3, 'Vacant'),
  createData('708', randomNum(55, 85), randomNum(30, 80), mode[0], 4, 'Occupied'),
  createData('811', randomNum(55, 85), randomNum(30, 80), mode[1], 5, 'Vacant'),
  createData('912', randomNum(55, 85), randomNum(30, 80), mode[0], 1, 'Occupied'),
  createData('913', randomNum(55, 85), randomNum(30, 80), mode[1], 1, 'Vacant'),
  createData('915', randomNum(55, 85), randomNum(30, 80), mode[0], 1, 'Occupied'),
];

const ThermostatPage = () => {
  const [units, setUnits] = useState(rows);
  const [selected, setSelected] = useState(units[0].unitNumber);
  const [statusFilters, setStatusFilters] = useState({});

  const renderUnitRow = (unit) => {
    let temperature;
    let humidity;
    let mode;
    const fontColor = { color: '#5D6679' };
    const isVacant = unit.status.toLowerCase() === vacantStatus;
    if (!isVacant) {
      temperature = unit.temperature > 82 || unit.temperature < 55 ?
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {unit.temperature} °F <Tooltip title={unit.temperature > 82 ? 'Temperature is higher than the preferred range' : 'Temperature is lower than the preferred range'}><InfoOutlinedIcon sx={{ color: 'red', marginLeft: '5px' }} /></Tooltip>
        </div> : 'Online';
      humidity = unit.humidity > 60 || unit.humidity < 35 ?
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {unit.humidity} % <Tooltip title={unit.humidity > 60 ? 'Humidity is higher than the preferred range' : 'Humidity is lower than the preferred range'}><InfoOutlinedIcon sx={{ color: 'red', marginLeft: '5px' }} /></Tooltip>
        </div> : 'Online';
      mode = '--';
    } else {
      temperature = `${unit.temperature} °F`;
      humidity = `${unit.humidity} %`;
      mode = unit.mode;
    }
    return (
      <TableRow
        hover
        selected={selected === unit.unitNumber}
        onClick={(event) => setSelected(unit.unitNumber)}
        key={unit.unitNumber}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell sx={fontColor} component="th" scope="row">
          {unit.unitNumber}
        </TableCell>
        <TableCell sx={fontColor} align="left">{temperature}</TableCell>
        <TableCell sx={fontColor} align="left">{humidity}</TableCell>
        <TableCell sx={fontColor} align="left">{mode}</TableCell>
        <TableCell sx={fontColor} align="left">{unit.unitFloor}</TableCell>
        <TableCell sx={fontColor} align="center">
          <Chip sx={{ color: 'white'}} label={unit.status} color={unit.status.toLowerCase() === vacantStatus ? 'primary' : 'secondary'} />
        </TableCell>
      </TableRow>
    );
  };

  const filterByStatus = (status) => {
    const newFilters = { ...statusFilters };
    Object.keys(statusFilters).map((s) => {
      return newFilters[s].isSelected = s === status;
    });
    const filteredUnits = rows.filter(u => u.status === status || status === defaultStatusFilter);
    setSelected(filteredUnits[0].unitNumber);
    setUnits(filteredUnits);
    setStatusFilters(newFilters);
  }

  useEffect(() => {
    const statusCounts = { [defaultStatusFilter]: { isSelected: true, count: 0 }};

    // Count the occurrences of each status in the data
    rows.forEach((row) => {
      const status = row.status;

      statusCounts[defaultStatusFilter].count++;

      if (statusCounts[status]) {
        statusCounts[status].count++;
      } else {
        statusCounts[status] = { isSelected: false, count: 1 };
      }
    });

    setStatusFilters(statusCounts);
  }, []);

  return (
    <div className="main-container">
      <div style={{ width: '100%' }}>
        <Header title="The Current"/>
      </div>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ padding: '25px' }}>
        <Grid item xs={12} lg={6}>
          <Section elevation={2}>
            <div className="filter-chips">
              {Object.keys(statusFilters).map((status) => (
                <Chip
                  key={status}
                  label={`${status} (${statusFilters[status].count})`}
                  variant={statusFilters[status].isSelected ? '' : 'outlined'}
                  style={{ marginLeft: '9px' }}
                  onClick={() => filterByStatus(status)}
                />
              ))}
            </div>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Unit Number</TableCell>
                    <TableCell align="left">Temperature</TableCell>
                    <TableCell align="left">Humidity</TableCell>
                    <TableCell align="left">Mode</TableCell>
                    <TableCell align="left">Unit Floor</TableCell>
                    <TableCell align="center">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {units.map((row) => (
                    renderUnitRow(row)
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Section>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Section elevation={2}>
            <ThermostatControls unit={units.find(u => u.unitNumber === selected)}/>
          </Section>
        </Grid>
      </Grid>
    </div>
  );
};

export default ThermostatPage;
