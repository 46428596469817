import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../statics/styles/components/sidebar.scss';
import AnalyticsIcon from '../statics/images/svg/analyticsIcon';
import MessagingIcon from '../statics/images/svg/messagingIcon';
import FilesIcon from '../statics/images/svg/filesIcon';
import SettingsIcon from '../statics/images/svg/settingsIcon';
import MegaphoneIcon from '../statics/images/svg/megaphoneIcon';
import ChatIcon from '../statics/images/svg/chatIcon';
import { paths } from '../lib/routes';
import { Avatar, Divider, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { getUser } from '../slices/auth';
import LogoutIcon from '@mui/icons-material/Logout';
import Tooltip from '@mui/material/Tooltip';
import { useAuth } from './AuthContext';
import BillingIcon from "../statics/images/svg/billingIcon";

const SidebarLink = ({ to, icon: IconComponent, iconSize, activeLink, onClick, isLast }) => (
  <>
    <Link
      to={to}
      onClick={() => onClick(to)}
      className={activeLink === to ? 'active-link' : ''}
    >
      <IconComponent {...iconSize} isSelected={activeLink === to} />
    </Link>
    { !isLast && <Divider style={{ height: '2px', width: '29%', margin: '10px 0px', background: '#545F69' }} /> }
  </>
);

const Sidebar = () => {
  const user = useSelector(getUser);
  const { logout } = useAuth();
  const location = useLocation();
  const isVisible = location.pathname !== '/login';
  const [activeLink, setActiveLink] = useState(location.pathname);

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };

  const iconSize = { height: '60px', width: '60px' };

  const links = [
    { path: paths.HOME, icon: AnalyticsIcon },
    { path: paths.BLAST, icon: MegaphoneIcon },
    { path: paths.CONVERSATIONS, icon: MessagingIcon },
    { path: paths.CHATS, icon: ChatIcon },
    { path: paths.DOCUMENTS, icon: FilesIcon },
    { path: paths.SETTINGS, icon: SettingsIcon },
    { path: paths.BILLING, icon: BillingIcon },
  ];

  if (!isVisible) {
    return null;
  }

  return (
    <div className="sidebar">
      <Tooltip title={user?.displayName}>
        <Avatar
          style={{
            position: 'absolute',
            top: '30px',
            width: '40px',
            height: '40px',
            background: !user?.photoURL && '#689B5E',
          }}
          alt={user?.displayName}
          src={user?.photoURL || '/broken'}
        />
      </Tooltip>

      {links.map(({ path, icon }, index) => (
        <SidebarLink
          key={path}
          to={path}
          icon={icon}
          iconSize={iconSize}
          activeLink={activeLink}
          onClick={handleLinkClick}
          isLast={(index - (links.length - 1)) === 0}
        />
      ))}

      <IconButton
        aria-label="logout"
        onClick={logout}
        style={{
          position: 'absolute',
          bottom: '40px',
          width: '40px',
          height: '40px',
        }}
      >
        <LogoutIcon
          style={{
            padding: '7px',
            width: '44px',
            height: '44px',
          }}
        />
      </IconButton>
    </div>
  );
};

export default Sidebar;
