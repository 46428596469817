import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db, storage } from '../app/firebase';
import { ref, listAll, getDownloadURL, getMetadata } from 'firebase/storage';
import { addDoc, collection, doc, serverTimestamp } from 'firebase/firestore';

export const fetchUploadedDocuments = createAsyncThunk('document/fetchUploadedDocuments', async (_, { getState }) => {
  const state = getState();
  const storageRef = ref(storage, `${state.building.currentBuilding.id}`);

  const result = await listAll(storageRef);
  const documentPromises = result.items.map(async (item) => {
    const downloadUrl = await getDownloadURL(item);
    const metadata = await getMetadata(item);
    return { name: item.name, downloadUrl, metadata };
  });

  return await Promise.all(documentPromises);
});

export const sendDocumentUploadNotification = createAsyncThunk(
  'document/sendDocumentUploadNotification',
  async (payload, { getState }) => {
    const { building: { organization, currentBuilding: { id: buildingId, name: buildingName } }, auth: { user } } = getState();
    const author = user?.displayName ?? 'Unknown User';
    const connectionRef = doc(db, 'organization', organization.id, 'building', buildingId);
    const uploadDocumentRef = collection(connectionRef, 'documentUpload');
    const text = `${author} has uploaded the following document ${payload.name}:${payload.downloadUrl} for the building, ${buildingName} with the following comment: ${payload.uploadComment}`

    const data = {
      ...payload,
      to: ['hamza@heyhio.com', 'jack@heyhio.com'],
      message: {
        subject: 'Upload Document Request',
        text,
      },
      author,
      timestamp: serverTimestamp(),
    };

    await addDoc(uploadDocumentRef, data);
    return payload;
});

export const sendDocumentDeletionNotification = createAsyncThunk(
  'document/sendDocumentDeletionNotification',
  async ({ document }, { getState }) => {
    const { building: { organization, currentBuilding: { id: buildingId, name: buildingName } }, auth: { user } } = getState();
    const author = user?.displayName ?? 'Unknown User';
    const connectionRef = doc(db, 'organization', organization.id, 'building', buildingId);
    const deleteDocumentRef = collection(connectionRef, 'documentUpload');
    const text = `The user would like to delete a file:
      Building: ${buildingName}
      Document Name: ${document.name}
      Author: ${author}`;

    const data = {
      document: JSON.stringify(document),
      to: ['hamza@heyhio.com', 'jack@heyhio.com'],
      message: {
        subject: 'Deletion Document Request',
        text,
      },
      author,
      timestamp: serverTimestamp(),
    };

    await addDoc(deleteDocumentRef, data);
    return document;
});

const documentSlice = createSlice({
  name: 'document',
  initialState: { documents: [], loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUploadedDocuments.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUploadedDocuments.fulfilled, (state, action) => {
        state.loading = false;
        state.documents = action.payload;
      })
      .addCase(fetchUploadedDocuments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(sendDocumentUploadNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendDocumentUploadNotification.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendDocumentUploadNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(sendDocumentDeletionNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendDocumentDeletionNotification.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendDocumentDeletionNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const getUploadedDocuments = (state) => state.document;

export default documentSlice.reducer;
