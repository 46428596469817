import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {doc, collection, getDocs, addDoc, updateDoc, deleteDoc, where, query} from "firebase/firestore";
import { db } from '../app/firebase';

const initialState = {
  accessToken: null,
  user: {},
  claims: {},
  admins: {
    list: [],
    loading: false,
    error: null,
  },
  staff: {
    list: [],
    loading: false,
    error: null,
  },
  users: {
    list: [],
    loading: false,
    error: null,
  },
  error: null,
  loading: false,
  status: 'idle',
};

export const fetchAdmins = createAsyncThunk('auth/fetchAdmins', async (_, { getState }) => {
  const { building: { organization } } = getState();
  const adminSnapshot = await collection(db, 'organization', organization?.id, 'admin');
  return (await getDocs(adminSnapshot)).docs.map(e => e.data());
});

export const fetchStaff = createAsyncThunk('auth/fetchStaff', async (_, { getState }) => {
  const { building: { organization, currentBuilding } } = getState();

  if (!currentBuilding.isPrivate) {
    return [];
  }

  const staffSnapshot = await collection(
    db,
    'organization',
    organization?.id,
    'building',
    currentBuilding.id,
    'user',
  );

  return (await getDocs(staffSnapshot)).docs.map(e => ({ id: e.id, ...e.data() }));
});

export const fetchUsers = createAsyncThunk('auth/fetchUsers', async (_, { getState }) => {
  const { building: { organization, currentBuilding } } = getState();

  const userSnapshot = await collection(
    db,
    'organization',
    organization?.id,
    'building',
    currentBuilding.id,
    'chat',
  );

  return (await getDocs(userSnapshot)).docs.map(e => ({ id: e.id, ...e.data() }));
});

export const setStaffUser = createAsyncThunk(
  'auth/setStaffUser',
  async (data, { getState, rejectWithValue }) => {
    const { building: { organization, currentBuilding } } = getState();

    if (!currentBuilding.isPrivate) {
      return rejectWithValue('Cannot set users for private building.')
    }

    const staffSnapshot = await collection(
      db,
      'organization',
      organization?.id,
      'building',
      currentBuilding.id,
      'user',
    );

    await addDoc(staffSnapshot, data);

    return data;
  }
);

export const updateStaffUser = createAsyncThunk(
  'auth/updateStaffUser',
  async (data, { getState, rejectWithValue }) => {
    const { building: { organization, currentBuilding } } = getState();

    if (!currentBuilding.isPrivate) {
      return rejectWithValue('Cannot set users for private building.')
    }

    const staffSnapshot = await doc(
      db,
      'organization',
      organization?.id,
      'building',
      currentBuilding.id,
      'user',
      data.id
    );

    await updateDoc(staffSnapshot, data);
    return data;
  }
);

export const deleteStaff = createAsyncThunk(
  'auth/deleteStaff',
  async (staffId, { getState, rejectWithValue }) => {
    const { building: { organization, currentBuilding } } = getState();

    if (!currentBuilding.isPrivate) {
      return rejectWithValue('Cannot delete users for private building.');
    }

    const staffDocRef = doc(
      db,
      'organization',
      organization?.id,
      'building',
      currentBuilding.id,
      'user',
      staffId,
    );

    await deleteDoc(staffDocRef);

    return staffId;
  }
);

export const authReducer = createSlice({
  name: 'auth',
  initialState,
  // The `slices` field lets us define slices and generate associated actions
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserClaims: (state, action) => {
      state.claims = action.payload;
    },
    setUserAccessToken: (state, action) => {
      state.accessToken = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdmins.pending, (state) => {
        state.admins.loading = true;
      })
      .addCase(fetchAdmins.fulfilled, (state, action) => {
        state.admins.loading = false;
        state.admins.list = action.payload;
        state.admins.error = null;
      })
      .addCase(fetchAdmins.rejected, (state, action) => {
        state.admins.loading = false;
        state.admins.error = action.error.message;
      })
      .addCase(fetchStaff.pending, (state) => {
        state.staff.loading = true;
      })
      .addCase(fetchStaff.fulfilled, (state, action) => {
        state.staff.loading = false;
        state.staff.list = action.payload;
        state.staff.error = null;
      })
      .addCase(fetchStaff.rejected, (state, action) => {
        state.staff.loading = false;
        state.staff.error = action.error.message;
      })
      .addCase(fetchUsers.pending, (state) => {
        state.users.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users.loading = false;
        state.users.list = action.payload;
        state.users.error = null;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.users.loading = false;
        state.users.error = action.error.message;
      })
      .addCase(setStaffUser.fulfilled, (state, action) => {
        state.staff.list = [action.payload, ...state.staff.list];
      })
      .addCase(updateStaffUser.pending, (state) => {
        state.staff.loading = true;
      })
      .addCase(updateStaffUser.fulfilled, (state, action) => {
        state.staff.loading = false;
        state.staff.list = [...state.staff.list.filter(staff => staff.id !== action.payload.id), action.payload];
        state.staff.error = null;
      })
      .addCase(updateStaffUser.rejected, (state, action) => {
        state.staff.loading = false;
        state.staff.error = action.error.message;
      })
      .addCase(deleteStaff.pending, (state) => {
        state.staff.loading = true;
      })
      .addCase(deleteStaff.fulfilled, (state, action) => {
        state.staff.loading = false;
        state.staff.list = state.staff.list.filter(staff => staff.id !== action.payload);
        state.staff.error = null;
      })
      .addCase(deleteStaff.rejected, (state, action) => {
        state.staff.loading = false;
        state.staff.error = action.error.message;
      });
  },
});

export const { setUser, setUserClaims, setUserAccessToken } = authReducer.actions;

export const getUser = (state) => state.auth.user;

export const getUserAccessToken = (state) => state.auth.accessToken;

export const getAdmins = (state) => state.auth.admins.list;

export const getStaff = (state) => state.auth.staff.list;

export const getUsers = (state) => state.auth.users.list;

export default authReducer.reducer;
