import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, where, query, getDoc, getDocs, setDoc, doc, limit, collectionGroup} from 'firebase/firestore';
import { db } from '../app/firebase';

const initialState = {
  error: null,
  organization: null, //TODO: Move this out when more organization level stuff is needed
  buildings: [],
  currentBuilding: null,
  status: 'idle',
};

export const setUserBuildings = createAsyncThunk(
  'building/setUserBuildings',
  async (_, { dispatch, getState }) => {
    const { organization } = getState().auth.claims;
    if (!organization) {
      throw Error('User is not linked to an organization');
    }

    const organizationRef = doc(db, 'organization', organization);
    const organizationDocSnapshot = await getDoc(organizationRef);

    await dispatch(setOrganization({ id: organizationRef.id, ...organizationDocSnapshot.data() }))

    // Query the organization collection to find the organization document
    const buildingRef = collection(db, 'organization', organization, 'building');

    const buildingSnapshot = await getDocs(buildingRef);

    const buildingDocuments = buildingSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    await dispatch(setCurrentBuilding(buildingDocuments[0]));

    return buildingDocuments;
  }
);

export const buildingReducer = createSlice({
  name: 'building',
  initialState,
  reducers: {
    setCurrentBuilding: (state, action) => {
      state.currentBuilding = action.payload;
    },
    setOrganization: (state, action) => {
      state.organization = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setUserBuildings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.buildings = action.payload;
      })
      .addCase(setUserBuildings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setUserBuildings.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = 'failed';
      });
  },
});

export const { setCurrentBuilding, setOrganization } = buildingReducer.actions;

export const getUserBuilding = (state) => state.building.currentBuilding;

export const getUserBuildings = (state) => state.building.buildings;

export default buildingReducer.reducer;
