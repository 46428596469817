import AnalyticsPage from '../pages/analytics'
import LoginPage from '../pages/login';
import RegisterPage from '../pages/register';
import ConversationsPage from '../pages/conversations';
import DocumentsPage from '../pages/documents';
import SettingsPage from '../pages/settings';
import BillingPage from '../pages/billing';
import ThermostatPage from '../pages/thermostat';
import BlastPage from '../pages/blast';
import ChatsPage from '../pages/chats';

export const paths = {
  HOME: '/',
  BLAST: '/blast',
  BILLING: '/billing',
  CONVERSATIONS: '/conversations',
  CHATS: '/chats',
  DOCUMENTS: '/documents',
  SETTINGS: '/settings',
  THERMOSTAT: '/thermostat',
  LOGIN: '/login',
  REGISTER: '/register',
  AUTHCODE: '/authcode',
}

const routes = [
  {
    path: paths.HOME,
    component: <AnalyticsPage />,
    private: true
  },
  {
    path: paths.BLAST,
    component: <BlastPage />,
    private: true
  },
  {
    path: paths.CONVERSATIONS,
    component: <ConversationsPage />,
    private: true
  },
  {
    path: paths.CHATS,
    component: <ChatsPage />,
    private: true
  },
  {
    path: paths.DOCUMENTS,
    component: <DocumentsPage />,
    private: true
  },
  {
    path: paths.SETTINGS,
    component: <SettingsPage />,
    private: true
  },
  {
    path: paths.BILLING,
    component: <BillingPage />,
    private: true
  },
  {
    path: paths.THERMOSTAT,
    component: <ThermostatPage />,
    private: true
  },
  {
    path: paths.LOGIN,
    component: <LoginPage />,
    private: false
  },
  {
    path: paths.REGISTER,
    component: <RegisterPage />,
    private: false
  }
];

export default routes;
