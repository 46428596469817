import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getUser
} from '../slices/auth'
import CircularProgress from '@mui/material/CircularProgress';

const PublicOnly = ({ children }) => {
  const user = useSelector(getUser);

  if (user && Object.keys(user).length !== 0) {
    return <Navigate to='/' />;
  }

  if (user && Object.keys(user).length === 0) {
    return <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '100vh'
    }}><CircularProgress />
    </div>
  }
  return children;
};

export default PublicOnly;
