import { Grid } from '@mui/material';
import { Dropzone, FileMosaic } from "@files-ui/react";
import Header from '../../components/Header';
import '../../statics/styles/pages/documents.scss';
import React, {useCallback, useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Paper from '@mui/material/Paper'
import { storage } from '../../app/firebase';
import { getDownloadURL, ref, uploadBytesResumable, updateMetadata } from 'firebase/storage';
import { useDispatch, useSelector } from 'react-redux';
import { getUserBuilding } from '../../slices/building';
import {
  fetchUploadedDocuments,
  getUploadedDocuments,
  sendDocumentDeletionNotification,
  sendDocumentUploadNotification
} from '../../slices/document';
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

const Section = styled(Paper)(({ theme }) => ({
  borderRadius: '12px',
  backgroundColor: 'white',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#31394d',
}));

const DocumentsPage = () => {
  const [error, setError] = useState('');
  const [files, setFiles] = useState([]);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [uploadStatus, setUploadStatus] = useState([]);
  const [uploadComment, setUploadComment] = useState('');
  const building = useSelector(getUserBuilding);
  const { documents, error: backendError } = useSelector(getUploadedDocuments);
  const dispatch = useDispatch();

  useEffect(() => {
    if (building?.id) {
      dispatch(fetchUploadedDocuments());
    }
  }, [building, dispatch]);

  const handleDownload = async (downloadUrl) => {
    if (downloadUrl) {
      window.open(downloadUrl, '_blank');
    }
  };

  const updateFiles = (incomingFiles) => {
    setFiles(incomingFiles);
  };
  const removeFile = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };

  const handleUploadStatusUpdate = (index, status) => {
    setUploadStatus((prevProgress) => {
      const newProgress = [...prevProgress];
      newProgress[index] = status;
      return newProgress;
    });
  }

  const notifyUserUpload = async (name, downloadUrl) => {
    try {
      await dispatch(sendDocumentUploadNotification({ name, downloadUrl, uploadComment }));
    } catch (error) {
      setError('There was an unknown error');
    }
    setUploadComment('');
  };

  const handleFileUpload = () => {
    if (files.length === 0) {
      setError('No files selected');
      return;
    }

    if (!building?.id) {
      setError('Account not linked');
      return;
    }

    files.forEach(({ file }, index) => {
      handleUploadStatusUpdate(index, { status: 'preparing' })

      const storageRef = ref(storage, `${building.id}/${file.name}`);

      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          handleUploadStatusUpdate(index, { status: 'uploading' , progress })
        },
        (error) => {
          setError(`Error uploading file ${file.name}`);
          handleUploadStatusUpdate(index, { status: 'error' , progress: 0 })
        },
        () => {
          handleUploadStatusUpdate(index, { status: 'success' , progress: 100 })
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            notifyUserUpload(file.name, url);
          });
          dispatch(fetchUploadedDocuments());
        }
      );
    });
  };

  const handleDelete = useCallback(async () => {
    try {
      const storageRef = ref(storage, `${building.id}/${documentToDelete.name}`);
      await updateMetadata(storageRef, {
        customMetadata: {
          stagedForDeletion: true,
        },
      });
      dispatch(fetchUploadedDocuments());
      await dispatch(sendDocumentDeletionNotification({ document: documentToDelete }));
      setDocumentToDelete(null);
    } catch (error) {
      setError('There was an unknown error');
    }
  }, [building, documentToDelete, dispatch]);

  return (
    <Grid className="main-container">
      <Header title="Reference Documents"/>
      {error && <Alert className="upload-error" variant="filled" severity="error">{error}</Alert>}
      {backendError && <Alert className="upload-error" variant="filled" severity="error">{backendError}</Alert>}
      <div className="upload-disclaimer">Reference documents are used to train your HIO models.  Use this page to upload new documents for your model(s) to digest and interpret.  New document additions may take up to 36 hours to process.</div>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ padding: '14px 100px;' }}>
        <Grid item xs={12}>
          <Section elevation={2}>
            <h3 className="documents-title">Upload a New Reference Document</h3>
            <div className="document-upload-container">
              <Dropzone
                label="Click here to Upload a Document"
                onChange={updateFiles}
                value={files}
              >
                {files.map((file, i) => (
                  <FileMosaic key={file.id} {...file} onDelete={removeFile} info uploadStatus={uploadStatus[i]?.status} progress={uploadStatus[i]?.progress} />
                ))}
              </Dropzone>
              <Section elevation={1} className="upload-comment-container">
                <div className="upload-comment-disc">How would you like to the model to use this document? Are there specific sections that are relevant?</div>
                <TextField
                  className="upload-comment-text-field"
                  label=""
                  multiline
                  placeholder=""
                  rows={4}
                  variant="outlined"
                  disableUnderline={true}
                  value={uploadComment}
                  onChange={e => setUploadComment(e.target.value)}
                />
              </Section>
              <div className="upload-button">
                <Button onClick={handleFileUpload} component="label" variant="contained" startIcon={<CloudUploadIcon />}>Confirm Upload</Button>
              </div>
            </div>
            <h3 className="documents-title">Currently Active Documents</h3>

            <div className="uploaded-documents">
              {documents.length !== 0 ?
                documents.map((d) => (<Chip icon={<FileCopyIcon />} className="uploaded-document" label={d.name} variant="outlined" disabled={d.metadata.customMetadata?.stagedForDeletion && d.metadata.customMetadata?.stagedForDeletion !== 'false'} clickable onClick={() => handleDownload(d.downloadUrl)} onDelete={() => setDocumentToDelete(d)} />)) :
                <Chip className="uploaded-document" label="No documents uploaded" color="primary" variant="outlined" />
              }
            </div>
          </Section>
        </Grid>
      </Grid>
      <Dialog open={!!documentToDelete} onClose={() => setDocumentToDelete(false)}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <DeleteTwoToneIcon color="error" sx={{ fontSize: '1.5em', marginRight: '5px' }} />Delete Document</DialogTitle>
        <DialogContent>
          <p>Are you sure you would like to remove <b>{documentToDelete?.name}</b> from <b>{building?.name}</b>? The document will be staged for deletion and will be removed from your model in 24 to 48 hours.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDocumentToDelete(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default DocumentsPage;
