import moment from 'moment';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useEffect, Fragment, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemText, Typography, Box } from '@mui/material';
import { getChats } from '../../../slices/usage';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import ExpandableText from '../../../components/ExpandableText';
import ReplyIcon from '@mui/icons-material/Reply';
import IconButton from '@mui/material/IconButton';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { paths } from '../../../lib/routes';

const Section = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#31394d',
  maxHeight: '84vh',
  overflowY: 'auto'
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  alignItems: 'stretch',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.log.alternate,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.log.main,
  },
  '&:first-of-type': {
    borderTopLeftRadius: '9px',
    borderTopRightRadius: '9px',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '9px',
    borderBottomRightRadius: '9px',
  },
}));

const StyledActions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
})

const ChatLog = () => {
  const [chatLog, setChatLog] = useState([]);
  const { chats, loading, error } = useSelector(getChats);
  const navigate = useNavigate();

  useEffect(() => {
    const messages = [];
    for (let i = chats.length - 2; i >= 0; i--) {
      const userMessage = chats[i];
      if (userMessage.message.role === 'user') {
        const assistantMessage = chats[i + 1];
        if (assistantMessage && assistantMessage.message.role === 'assistant') {
          const time = moment(userMessage.timestamp).format('MMMM Do YYYY, h:mm A');

          messages.push({
            id: i,
            time: time,
            name: userMessage?.user?.name,
            question: userMessage?.message?.content,
            answer: assistantMessage?.message?.content,
            phoneNumber: userMessage?.user?.phoneNumber,
          });

          i--;
        }
      }
    }

    setChatLog(messages);
  }, [chats]);

  const handleFixResponse = useCallback(({ question, answer }) => {
    navigate(`${paths.CONVERSATIONS}?question=${encodeURIComponent(question)}&answer=${encodeURIComponent(answer)}`);
  }, [navigate]);

  const handleReply = useCallback(({ phoneNumber }) => {
    navigate(`${paths.BLAST}?number=${phoneNumber.replace(/\D/g, '')}`);
  }, [navigate]);

  return (
    <Section elevation={2}>
      {loading ? <CircularProgress style={{ margin: '150px 0' }} /> :
        error ?
        <Alert variant="filled" severity="error">{error}</Alert> :
        <Box sx={{ bgcolor: 'background.paper', padding: '0px 12px 0 12px' }}>
          <h2 style={{
            textAlign: 'left',
            marginBottom: '0',
            color: '#31394D'
          }}>Answered Chats</h2>
          <List>
            {chatLog.map((message) => (
              <StyledListItem key={message.id} alignItems="center">
                <ListItemText
                  primary={
                    <Typography variant="body2" color="textSecondary">
                      {message.time}
                    </Typography>
                  }
                  secondary={
                    <Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                      >
                        {message.name ?? 'User'}: {message.question}
                      </Typography>
                      <br />
                      <ExpandableText text={message.answer} maxLength={100} />
                    </Fragment>
                  }
                />
                <StyledActions>
                  <IconButton edge="end" aria-label="fix" onClick={() => handleReply(message)}>
                    <ReplyIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="fix" onClick={() => handleFixResponse(message)}>
                    <AutoFixHighIcon />
                  </IconButton>
                </StyledActions>
              </StyledListItem>
            ))}
          </List>
        </Box>
      }
    </Section>
  );
};

export default ChatLog;
