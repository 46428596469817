import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getUser,
} from '../slices/auth'
import { CircularProgress } from '@mui/material';

const PrivateRoute = ({ children }) => {
  const user = useSelector(getUser);

  if (!user) {
    return <Navigate to='/login' />;
  }

  if (Object.keys(user).length === 0) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return children;
};

export default PrivateRoute;
