import React from 'react';
import '../statics/styles/components/navbar.scss';

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src="https://static.wixstatic.com/media/c1b522_47d5bc3aa3994171b18ea3e400413e86~mv2.png/v1/crop/x_0,y_19,w_605,h_859/fill/w_109,h_156,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/H%20square_greenleft%20(1).png"/>
      </div>
      <div className="navbar-links">
        <ul>
          <li><a href="https://www.heyhio.co">Home</a></li>
          <li><a href="#">About</a></li>
          <li><a href="#">Services</a></li>
          <li><a href="#">Contact</a></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
