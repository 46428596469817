import Header from '../../components/Header';
import { useState } from 'react';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Profile from './profile';
import Buildings from './service';
import Access from './access';

import '../../statics/styles/pages/settings.scss';

const Section = styled(Paper)(({ theme }) => ({
  borderRadius: '12px',
  backgroundColor: 'white',
  padding: '14px 8px',
  textAlign: 'center',
  color: '#31394d',
  margin: '31px 34px',
  width: '800px'
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      { value === index && children }
    </div>
  );
}

const SettingsPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid className="main-container">
      <Header title="Settings" hideBuildingSelector={value !== 2}/>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Section elevation={2}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="select setting tab">
              <Tab label="Account Profile" />
              <Tab label="Service Details" />
              <Tab label="Users" />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Profile />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Buildings />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Access />
          </CustomTabPanel>
        </Section>
      </Grid>
    </Grid>
  );
};

export default SettingsPage;
