import { memo, useEffect, useState } from 'react';

const ChatIcon = ({ isSelected, ...props }) => {
  const [fill, setFill] = useState('#545F69');

  useEffect(() => {
    setFill(isSelected ? 'white' : '#545F69');
  }, [isSelected]);

  const handleMouseEnter = () => {
    if (!isSelected) {
      setFill('white');
    }
  };

  const handleMouseLeave = () => {
    if (!isSelected) {
      setFill('#545F69');
    }
  };
  return (
    <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...props} style={{ padding: '13px' }}>
      <path d="M10.2083 12.3955H2.91667V9.47884H10.2083V12.3955ZM10.2083 16.7705H2.91667V19.6872H10.2083V16.7705ZM30.0271 26.9788L24.4417 21.3934C23.275 22.1518 21.9042 22.6038 20.4167 22.6038C16.3917 22.6038 13.125 19.3372 13.125 15.3122C13.125 11.2872 16.3917 8.02051 20.4167 8.02051C24.4417 8.02051 27.7083 11.2872 27.7083 15.3122C27.7083 16.7997 27.2563 18.1705 26.4979 19.3226L32.0833 24.9226L30.0271 26.9788ZM24.7917 15.3122C24.7917 12.9059 22.8229 10.9372 20.4167 10.9372C18.0104 10.9372 16.0417 12.9059 16.0417 15.3122C16.0417 17.7184 18.0104 19.6872 20.4167 19.6872C22.8229 19.6872 24.7917 17.7184 24.7917 15.3122ZM2.91667 26.9788H17.5V24.0622H2.91667V26.9788Z" fill={fill}/>
    </svg>
  );
};
export default memo(ChatIcon);

