import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { getUser } from '../../../slices/auth';

const Profile = () => {
  const { displayName, phoneNumber, email } = useSelector(getUser);
  const [firstName, lastName] = displayName?.split(' ') || [];

  return (
    <div className="profile">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            id="firstName"
            label="First Name"
            variant="outlined"
            defaultValue={firstName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            id="lastName"
            label="Last Name"
            variant="outlined"
            defaultValue={lastName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            id="phoneNumber"
            label="Phone Number"
            variant="outlined"
            defaultValue={phoneNumber}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            fullWidth
            id="email"
            label="Email"
            variant="outlined"
            defaultValue={email}
          />
        </Grid>
        <Grid className="profile-submit-btn" item xs={12}>
          <Button disabled variant="contained" color="primary" style={{ float: 'right' }}>
            Save Changes
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default Profile;
