import * as React from 'react';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { getUserBuilding } from '../../../slices/building';
import { fetchModelUpdateHistory, getModelUpdateHistory } from '../../../slices/model';
import { useEffect } from 'react';

const Section = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  padding: 0,
  textAlign: 'center',
  color: '#31394d',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: 'pre-line',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.table.main,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.table.alternate,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.table.main,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function HistoryTable() {
  const dispatch = useDispatch();
  const history = useSelector(getModelUpdateHistory);
  const building = useSelector(getUserBuilding);

  useEffect(() => {
    if (building?.id) {
      dispatch(fetchModelUpdateHistory());
    }
  }, [building]);

  return (
    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ padding: '25px' }}>
      <Grid item xs={12}>
        {history.length > 0 &&
          <Section elevation={2}>
            <TableContainer>
              <Toolbar
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  bgcolor: (theme) => theme.palette.table.main
                }}
              >
                <Typography
                  sx={{ flex: '1 1 100%' }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Previous Model Additions
                </Typography>
              </Toolbar>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Model Addition</StyledTableCell>
                    <StyledTableCell align="right">Added By</StyledTableCell>
                    <StyledTableCell align="right">Date Added</StyledTableCell>
                    <StyledTableCell align="right">Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {row.statement || `Q: ${row.question}\nA: ${row.answer}`}
                      </StyledTableCell>
                      <StyledTableCell align="right">{row.author}</StyledTableCell>
                      <StyledTableCell align="right">{moment(row.timestamp.toDate()).format('MMMM Do YYYY')}</StyledTableCell>
                      <StyledTableCell align="right">{row.status}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Section>
        }
      </Grid>
    </Grid>
  );
}