import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserBuilding, getUserBuildings, setCurrentBuilding } from '../slices/building';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function Header({ title, leftAlign, hideBuildingSelector }) {
  const dispatch = useDispatch();
  const building = useSelector(getUserBuilding);
  const buildings = useSelector(getUserBuildings);

  const style = {
    display: 'flex',
    padding: '25px',
    color: '#31394d',
    fontSize: '36px',
    fontWeight: 700,
    background: 'white',
    justifyContent: 'space-between',
    alignItems: 'center'
  }

  const buildingStyle = {
    width: 300,
    color: '#639FB6',
    fontSize: '20px',
    paddingLeft: leftAlign ? '24px' : '0'
  }

  const handleChange = useCallback((event, newBuilding) => {
    if (newBuilding) {
      dispatch(setCurrentBuilding(newBuilding))
    }
  }, [dispatch]);

  return (
    <div style={style}>
      <div>{title}</div>
      {!hideBuildingSelector &&
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          sx={buildingStyle}
          onChange={handleChange}
          options={buildings}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Building" />}
          value={buildings.find(item => item.id === building.id) || null}
        />
      }
    </div>
  );
}

export default Header;
