/* global thermostatDial */
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useEffect, useRef, useState } from 'react';

export const mode = ['Heat', 'Cool', 'Heat / Cool'];

const buttonLabelStyles = {
  margin: '8px 0px;',
  fontWeight: 600,
}

const setRandomTemperature = (unit) => {
  return (unit.temperature + parseFloat(((Math.random() * 8) - 4).toFixed(2)));
}

const setRandomHumidity = () => {
  return (((Math.random() * 11) + 45).toFixed(2))
}

const ThermostatControls = ({ unit }) => {
  const isVacant = unit.status === 'Vacant';
  const nestRef = useRef(null);
  const randomDeviation = Math.floor(Math.random() * (4)) + 1;
  const [averageTemperature, setAverageTemperature] = useState(setRandomTemperature(unit));
  const [averageHumidity, setAverageHumidity] = useState(setRandomHumidity());
  const [hvacMode, setHvacMode] = useState(unit.mode);
  const [targetTemperature, setTargetTemperature] = useState(unit.mode === mode[1] ?
    unit.temperature - randomDeviation : unit.temperature + randomDeviation
  );

  const changeHvacMode = (e) => {
    let temperature;
    const newMode = e.target.value;
    const randomDeviation = Math.floor(Math.random() * (4)) + 1;

    if (newMode === mode[0]) {
      temperature = unit.temperature + randomDeviation;
    } else {
      temperature = unit.temperature - randomDeviation;
    }
    nestRef.current.target_temperature = temperature;
    nestRef.current.hvac_state = newMode.toLowerCase();
    setTargetTemperature(temperature);
    setHvacMode(newMode);
  }

  const setTargetTemperatureUI = (temp) => {
    if (temp < unit.temperature) {
      nestRef.current.hvac_state = mode[1].toLowerCase();
      setHvacMode(mode[1]);
    } else {
      nestRef.current.hvac_state = mode[0].toLowerCase();
      setHvacMode(mode[0]);
    }
    nestRef.current.target_temperature = temp;
    setTargetTemperature(temp);
  }

  useEffect(() => {
    const newTemp = unit.mode === mode[1] ?
      unit.temperature - randomDeviation : unit.temperature + randomDeviation;
    if (nestRef.current) {
      nestRef.current.hvac_state = unit.mode.toLowerCase();
      nestRef.current.ambient_temperature = unit.temperature;
      nestRef.current.target_temperature = newTemp;
      setTargetTemperature(newTemp);
      setAverageTemperature(setRandomTemperature(unit));
      setAverageHumidity(setRandomHumidity());
      setHvacMode(unit.mode);
    }
  }, [unit])

  useEffect(() => {
    if (!nestRef.current) {
      const nestInstance = new thermostatDial(document.getElementById('thermostat'), {
        minValue: 60,
        maxValue: 90
      });

      nestInstance.hvac_state = unit.mode.toLowerCase();
      nestInstance.ambient_temperature = unit.temperature;
      nestInstance.target_temperature = targetTemperature;
      nestRef.current = nestInstance;
    }
  }, []);
  return (
    <Grid container justifyContent="center" alignItems="center" direction="column">
      <h3 style={{
        alignSelf: 'flex-start',
        color: 'rgb(49, 57, 77)',
        marginLeft: '17px',
        fontSize: '25px'
      }}>Unit {unit.unitNumber}</h3>
      {!isVacant &&
        <Grid container justifyContent="center" alignItems="center" direction="column" className="controls-locked">
          Temperature control unavailable for occupied units
        </Grid>
      }
      <Grid container justifyContent="center" alignItems="center" direction="column" sx={{ display: isVacant ? 'inherit' : 'none' }}>
        <FormControl sx={{ m: 1, minWidth: 120, alignSelf: 'flex-end' }} size="small">
          <InputLabel id="demo-select-small-label">Mode</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={hvacMode}
            label="Mode"
            onChange={changeHvacMode}
          >
            {
              mode.map((m) => <MenuItem value={m}>{m}</MenuItem>)
            }
          </Select>
        </FormControl>
        <div id='thermostat' />
        <Grid container justifyContent="center" alignItems="center" sx={{ margin: '23px 0' }}>
          <Grid item xs={6}>
            <p style={buttonLabelStyles}>Lower bound</p>
            <Fab size="medium" color="pastelRed" sx={{ color: 'white' }} aria-label="add" onClick={() => setTargetTemperatureUI(targetTemperature - 1)}>
              <RemoveIcon />
            </Fab>
          </Grid>

          <Grid item xs={6}>
            <p style={buttonLabelStyles}>Upper bound</p>
            <Fab size="medium" color="primary" aria-label="add" onClick={() => setTargetTemperatureUI(targetTemperature + 1)}>
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" sx={{ margin: '23px 0' }}>
        <Grid item xs={6}>
          <div className="average-container">
            <div className="average-label">Average Unit Temperature</div>
            <div className="average-data">{averageTemperature}</div>
            <div className="average-rects">
              <div className="average-rect-1" />
              <div className="average-rect-2" />
              <div className="average-rect-3" />
              <div className="average-rect-4" />
            </div>
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className="average-container">
            <div className="average-label">Average Unit Humidity</div>
            <div className="average-data">{averageHumidity}</div>
            <div className="average-rects">
              <div className="average-rect-1" />
              <div className="average-rect-2" />
              <div className="average-rect-3" />
              <div className="average-rect-4" />
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ThermostatControls;
