import { memo, useEffect, useState } from 'react';

const MessagingIcon = ({ isSelected, ...props }) => {
  const [fill, setFill] = useState('#545F69');

  useEffect(() => {
    setFill(isSelected ? 'white' : '#545F69');
  }, [isSelected]);

  const handleMouseEnter = () => {
    if (!isSelected) {
      setFill('white');
    }
  };

  const handleMouseLeave = () => {
    if (!isSelected) {
      setFill('#545F69');
    }
  };
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd"
          d="M21.551 17.39L19.3127 15.335C19.1314 15.165 18.8963 15.075 18.6563 15.075H6.45551V13.075H18.0343C18.5731 13.075 19.0139 12.63 19.0139 12.075V6H21.551V17.39ZM4.19755 11.335L1.95918 13.39V2H17.0547V11.075H4.85388C4.73143 11.075 4.60898 11.1 4.49633 11.145C4.38857 11.19 4.28571 11.25 4.19755 11.335ZM22.5306 4H19.0139V1C19.0139 0.445 18.5731 0 18.0343 0H0.979592C0.440816 0 0 0.445 0 1V15.635C0 16.03 0.225306 16.39 0.582857 16.55C0.710204 16.61 0.842449 16.635 0.979592 16.635C1.21469 16.635 1.4498 16.55 1.63592 16.38L4.49633 13.75V16.075C4.49633 16.63 4.93714 17.075 5.47592 17.075H18.2792L21.8743 20.38C22.0604 20.55 22.2955 20.635 22.5306 20.635C22.6678 20.635 22.8 20.61 22.9273 20.55C23.2849 20.39 23.5102 20.03 23.5102 19.635V5C23.5102 4.445 23.0694 4 22.5306 4Z"
          fill={fill} />
    </svg>
  );
};
export default memo(MessagingIcon);

